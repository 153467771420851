import "./index.scss";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Button, Form, Input, Select, Tooltip } from "antd";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import HeaderTitle from "../../../../../components/HeaderTitle";
import DropdownSvg from "../../../../../assets/下拉.svg";
import Scrollbars from "react-custom-scrollbars";
import projectApi from "../../../../../apis/project";
import { useSelector } from "react-redux";
import MySpin from "../../../../../components/MySpin";
import MessageModal from "../../../../../components/MessageModal";

const { Option } = Select;
const { TextArea } = Input;
const DialogDrawer = (props) => {
  const [form] = Form.useForm();
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  const {
    DialogDrawerVisible,
    setDialogDrawerVisible,
    deployModelList, // 需要部署的模型数据
    loading,
    setDeployModelList, // 设置需要部署的模型数据
    getModeldataList, // 更新部署页面
    deployedModelInfo, // 已经部署的默认选中项
  } = props;

  // 存储类型
  const DBType = dicCodeInfoList?.DG_DB_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  const [modeSwitch, setModeSwitch] = useState(true); // 模型类型选择切换
  const [MetadataStorageList, setMetadataStorageList] = useState([]); // 数据存储列表
  const [scriptValue, setScriptValue] = useState([]); // 执行脚本信息id信息
  const [TextAreaValue, setTextAreaValue] = useState({}); // 当前执行信息
  const [deploymentValue, setDeploymentValue] = useState(""); // 部署结果信息
  const [DbSchemasList, setDbSchemasList] = useState([]);
  const [execute, setExecute] = useState(null); // 控制执行按钮

  // 数据存储列表查询
  const getMetadataStorage = () => {
    projectApi.getMetadataStorage().then((res) => {
      if (res.code === 200) {
        setMetadataStorageList(res.data.items);
      } else {
        setMetadataStorageList([]);
      }
    });
  };
  useEffect(() => {
    getMetadataStorage();
  }, []);

  useEffect(() => {
    if (DialogDrawerVisible && Object.keys(deployedModelInfo)?.length !== 0) {
      // console.log(deployedModelInfo);
      getDbSchemas(deployedModelInfo?.metadata_id);
      form.setFieldsValue({
        ds_metadata_id: deployedModelInfo?.metadata_id,
        ds_schema: deployedModelInfo?.ds_schema,
      });
    }
  }, [deployedModelInfo]);

  // 模型状态颜色切换
  const modelColor = (item) => {
    if (item === "NEW" || item === '"ORIGINAL"') {
      return "#FEC054";
    } else if (item === "UPDATE") {
      return "#006AB2";
    } else if (item === "DISCARD") {
      return "#FE545F";
    }
  };
  // 获取schemas信息
  const getDbSchemas = (id) => {
    projectApi.getDbSchemas(id).then((res) => {
      if (res.code === 200) {
        setDbSchemasList(res.data);
      } else {
        setDbSchemasList([]);
      }
    });
  };

  // 获取部署ddl信息
  const getTableDdl = (data) => {
    projectApi.getTableDdl(data).then((res) => {
      if (res.code === 200) {
        setTextAreaValue({ ...res.data, table_id: data.table_id });
        setScriptValue([
          ...scriptValue,
          { table_id: data.table_id, ...res.data },
        ]);
      }
    });
  };
  const TextAreaChange = (e) => {
    setTextAreaValue({
      ...TextAreaValue,
      ddl: e.target.value,
    });

    const data = scriptValue.map((item) => {
      if (item.ddl_id === TextAreaValue.ddl_id) {
        return { ...item, ddl: e.target.value };
      }
      return item;
    });
    setScriptValue(data);
  };

  // 模型部署
  const deployModelDesign = (data) => {
    projectApi.deployModelDesign(data).then((res) => {
      if (res.code === 200) {
        // 按钮控制
        const Execute = Object.values(res.data).every((item) => item.status);
        setExecute(Execute);
        // 部署结果展示
        const data = Object.values(res.data)
          .map((item) => item.message)
          ?.toString();
        setDeploymentValue(data);
        // 动态设定模型列表展示情况
        let arr = deployModelList?.reduce(
          (cur, pre) => [
            ...cur,
            { status: res.data[pre.table_id]?.status, ...pre },
          ],
          []
        );
        setDeployModelList(arr);

        const info = arr.find(
          (obj) => obj?.table_id === TextAreaValue?.table_id
        );
        if (info !== undefined) {
          setTextAreaValue({
            ...TextAreaValue,
            status: info?.status,
          });
        }
      } else {
        setDeploymentValue("");
      }
    });
  };

  const onFinish = (value) => {
    const data = deployModelList
      ?.filter((item) => item.status !== true)
      ?.reduce(
        (cur, pre) => [
          ...cur,
          {
            table_id: pre?.table_id,
            dg_chg_type: pre?.chg_type_code,
            table_name: pre?.table_name,
          },
        ],
        []
      );
    let newArray = data.map((obj1) => {
      let matchingObj = scriptValue.find(
        (obj2) => obj2.table_id === obj1.table_id
      );
      if (matchingObj) {
        return {
          ...obj1,
          ddl_id: matchingObj.ddl_id,
          ddl: matchingObj.ddl,
        };
      }

      return obj1;
    });

    if (execute !== true) {
      MessageModal({
        description: "确认继续部署吗？",
        message: "部署存在风险，请务必确保脚本正确并且数据已经备份",
        title: "部署确认",
        okText: "部署",
        onOk: () =>
          deployModelDesign({
            table_deploys: newArray,
            ds_metadata_id: value.ds_metadata_id,
            ds_schema: value.ds_schema,
          }),
      });
    } else {
      setDialogDrawerVisible(false);
    }
  };

  return (
    <div className="ModelApplyDrawer">
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          className="dialogDrawerForm"
          preserve={false}
          onFinish={onFinish}
        >
          {loading ? (
            <MySpin></MySpin>
          ) : (
            <>
              <HeaderTitle title="部署设置"></HeaderTitle>
              <Form.Item
                label="数据存储"
                name="ds_metadata_id"
                rules={[
                  {
                    required: true,
                    message: "请选择数据存储",
                  },
                ]}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch={true}
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  style={{ width: "360px" }}
                  onChange={(e) => {
                    form.setFieldsValue({
                      ds_schema: null,
                    });

                    if (e !== undefined && e !== null) {
                      getDbSchemas(e);
                    }
                  }}
                >
                  {MetadataStorageList?.map((item, index) => {
                    return (
                      <Option value={item.metadata_id} key={index}>
                        {`${item.chi_name} / ${item.eng_name}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Schema"
                name="ds_schema"
                style={{ width: "500px" }}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch={true}
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  style={{ width: "360px" }}
                >
                  {DbSchemasList?.map((item, index) => {
                    return (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <HeaderTitle title="部署脚本"></HeaderTitle>
              <div className="modalApply">
                <div className="modalApplyItem">
                  <div className="modalLabel">模型列表</div>
                  <div
                    className="modalValue"
                    style={{
                      width: "444px",
                      height: "515px",
                      marginRight: "24px",
                      border: "1px solid #d9d9d9",
                      padding: "20px",
                    }}
                  >
                    <Scrollbars
                      autoHide={true}
                      autoHideTimeout={5000}
                      style={{ height: "100%", width: "100%" }}
                    >
                      {deployModelList?.map((item) => (
                        <div className="tableInfo" key={item?.table_id}>
                          <div
                            className="typeValue"
                            style={{
                              backgroundColor: modelColor(item?.chg_type_code),
                            }}
                          >
                            {item?.chg_type_code_value}
                          </div>
                          <div
                            className="nameValue"
                            onClick={() => {
                              const data = scriptValue.find(
                                (obj) => obj?.table_id === item?.table_id
                              );
                              const info = deployModelList.find(
                                (obj) => obj?.table_id === item?.table_id
                              );
                              if (data !== undefined) {
                                setTextAreaValue({
                                  table_id: data?.table_id,
                                  ddl: data.ddl,
                                  ddl_id: data.ddl_id,
                                  status: info?.status,
                                });
                              } else {
                                getTableDdl({
                                  table_id: item?.table_id,
                                  dg_chg_type: item?.chg_type_code,
                                });
                              }
                            }}
                          >
                            <Tooltip
                              title={`${item?.table_cmnt} / ${item?.table_name}`}
                              overlayClassName="sharesTip"
                            >
                              {`${item?.table_cmnt} / ${item?.table_name}`}
                            </Tooltip>
                          </div>
                          {item?.status ? (
                            <CheckCircleFilled
                              style={{ color: "#61D4AD", fontSize: "16px" }}
                            />
                          ) : item?.status === false ? (
                            <CloseCircleFilled
                              style={{ color: "#FE545F", fontSize: "16px" }}
                            />
                          ) : null}
                        </div>
                      ))}
                    </Scrollbars>
                  </div>
                </div>

                <div className="modalApplyItem">
                  <div className="modalLabel">执行脚本</div>
                  <div
                    className="modalValue"
                    style={{ width: "730px", marginRight: "24px" }}
                  >
                    <TextArea
                      allowClear
                      autoSize={{ minRows: 23 }}
                      disabled={TextAreaValue?.status}
                      onChange={TextAreaChange}
                      value={TextAreaValue.ddl}
                    />
                  </div>
                </div>
              </div>
              <div className="modalApplyItem" style={{ width: "100%" }}>
                <div className="modalLabel">部署结果</div>
                <div
                  className="modalValue"
                  style={{ width: "1200px", marginBottom: "10px" }}
                >
                  <TextArea
                    allowClear
                    autoSize={{ minRows: deployModelList?.length < 4 ? 6 : 15 }}
                    disabled
                    value={deploymentValue}
                  />
                </div>
              </div>
              <div className="drawerFooterBtnBox">
                <Button
                  onClick={() => {
                    form.submit();
                    if (execute) {
                      getModeldataList();
                    }
                  }}
                  style={{
                    background: "#016DB4",
                    borderColor: "#016DB4",
                    color: "#FFFFFF",
                  }}
                >
                  {execute
                    ? "关闭"
                    : execute === false
                    ? "重试"
                    : "   开始执行"}
                </Button>
                <Button
                  onClick={() => {
                    setDialogDrawerVisible(false);
                  }}
                  style={{
                    background: "#fff",
                  }}
                >
                  {execute !== true ? " 取消" : null}
                </Button>
              </div>
            </>
          )}
        </Form>
      </Scrollbars>
    </div>
  );
};

export default DialogDrawer;
