import "./index.scss";
import { useEffect, useState, useRef } from "react";

import projectApi from "../../../../../apis/project";
import {
  Drawer,
  Button,
  Form,
  Input,
  Tooltip,
  Checkbox,
  Pagination,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";

import Scrollbars from "react-custom-scrollbars";
import HeaderTitle from "../../../../../components/HeaderTitle";

import MySpin from "../../../../../components/MySpin";

const { TextArea } = Input;

const DialogDrawer = (props) => {
  const {
    /* 关闭触发的回调 */
    onCancel,
    /* 确定触发的回调 */
    onOk,
    visible,
    /* 判断新增还是修改 */
    addNew,
    loading,
    /*  api数据服务列表 */
    storeList,
    changePagination,
    //api服务包列表（已申请）
    storePackageList,
  } = props;

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();

  const [selectedValues, setSelectedValues] = useState([]);

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  useEffect(() => {
    setTotal(storeList?.total);
    const defaultSelectedValues = storePackageList.map((info) => {
      const matchedItem = storeList?.items.find(
        (data) => data.api_pkg_id === info.api_pkg_id
      );
      return matchedItem ? matchedItem.api_pkg_id : [];
    });

    setSelectedValues(defaultSelectedValues);
    form.setFieldsValue({ api_pkg_id: defaultSelectedValues });
  }, [visible, storeList]);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  // 提交表单录入
  const onFinish = (value) => {
    const uniqueApiPkgId = Array.from(new Set(value.api_pkg_id));
    const resultData = {
      ...value,
      api_pkg_id: uniqueApiPkgId,
    };
    onOk && onOk(resultData);
  };

  return (
    <Drawer
      title={"服务申请"}
      placement="right"
      width="600px"
      className="overviewDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              form.submit();
            }}
            style={{ background: "#016DB4", color: "#fff" }}
          >
            提交申请
          </Button>

          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            取消
          </Button>
        </div>
      }
    >
      {loading ? <MySpin></MySpin> : null}
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="dialogDrawerForm"
          preserve={false}
          onFinish={onFinish}
        >
          <HeaderTitle title="API服务包列表"></HeaderTitle>
          <Form.Item name="api_pkg_id">
            <Checkbox.Group style={{ width: "100%" }}>
              {storeList?.items?.map((data) => (
                <Tooltip
                  overlayClassName="headerTipContent"
                  placement="bottomLeft"
                  title={
                    <div className="headerTip">
                      <div className="headerTipTitle">{`${data?.chi_name} / ${data?.eng_name}`}</div>
                      <div className="headerTipText">
                        服务描述: {data?.api_pkg_desc}
                      </div>
                    </div>
                  }
                >
                  <Checkbox
                    value={data.api_pkg_id}
                    disabled={selectedValues.includes(data.api_pkg_id)}
                  >
                    {`${data?.chi_name} / ${data?.eng_name}`}
                  </Checkbox>
                </Tooltip>
              ))}
            </Checkbox.Group>
          </Form.Item>
          {total >= 1 ? (
            <div className="paginationBox">
              <Pagination
                current={pageNumber}
                total={total}
                pageSize={pageSize}
                onChange={onChange}
              />
            </div>
          ) : null}

          <Form.Item
            name="apply_purp"
            label="申请原因"
            rules={[
              {
                required: true,
                message: "请输入申请原因",
              },
            ]}
          >
            <TextArea
              allowClear
              style={{
                width: "540px",
              }}
              autoSize={{ minRows: 5 }}
            />
          </Form.Item>
        </Form>
      </Scrollbars>
    </Drawer>
  );
};

export default DialogDrawer;
