import "./index.scss";
import Scrollbars from "react-custom-scrollbars";

import { Input, Pagination, message, Button, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import MySpin from "../../../components/MySpin";
import { useSelector } from "react-redux";
import Empty from "../../../components/Empty";
import projectApi from "../../../apis/project";
import ServiceDevCard from "./components/ServiceDevCard";
import DialogDrawer from "./components/DialogDrawer";
import MessageModal from "../../../components/MessageModal";
import DevDetailDrawer from "./components/DevDetailDrawer";
import { getToken } from "../../../unit/index";
const CheckboxGroup = Checkbox.Group;
const orderDataList = [
  { label: "中文名称", key: "chi_name" },
  { label: "英文名称", key: "eng_name" },
  { label: "创建时间", key: "created_time" },
  { label: "更新时间", key: "updated_time" },
];

const ServiceDev = (props) => {
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  // 状态
  const newstatuDataType = dicCodeInfoList?.DG_API_RLS_STAT
    ? [
        {
          all: "全部",
        },
        ...dicCodeInfoList?.DG_API_RLS_STAT,
      ]
    : [];

  const statuDataType = newstatuDataType
    ?.map((item) => Object.keys(item))
    .flat();

  const [statuCode, setStatuCode] = useState("all"); // 状态选择
  const [serviceName, setServiceName] = useState(""); // api服务名称

  const [upOrderCode, setUpOrderCode] = useState({}); // 上排序选择
  const [downOrderCode, setDownOrderCode] = useState({}); // 下排序选择

  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);
  const [DialogLoading, setDialogLoading] = useState(false);
  const [defaultData, setDefaultData] = useState(null); // 编辑服务信息
  const [release, setRelease] = useState(false); // 提交审核判断
  // const navigate = useNavigate(); // 跳转api平台

  const [devPackageList, setDevPackageList] = useState([]); // 服务开发列表
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [orderList, setOrderList] = useState({
    orders: [
      {
        order_name: "updated_time",
        order_by: "desc",
      },
    ],
  }); // 筛选条件
  const [loading, setLoading] = useState(false);
  const [verId, setVerId] = useState("");
  const [VersonList, setVersonList] = useState(null); // 详情版本信息
  const [DetailDrawerVisible, setDetailDrawerVisible] = useState(false); // 查看详情
  const [DetailDrawerLoading, setDetailDrawerLoading] = useState(false); // 查看详情loading
  const [DetailInfo, setDetailInfo] = useState({}); // 详情数据
  const [submitCheckData, setSubmitCheckData] = useState([]); // 提交审核数据

  const [checkAll, setCheckAll] = useState(false); // 控制全选
  const [checkedList, setCheckedList] = useState([]);
  const [plainOptionList, setPlainOptionList] = useState([]); // 全选反选绑定数据
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true); // 控制反选

  // 全选控制小选
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptionList : []);
    setIndeterminate(false);
    setChecked(e.target.checked ? true : false);
    setCheckAll(e.target.checked);
  };

  // 小选控制全选
  const checkChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptionList.length);
    setCheckAll(list.length === plainOptionList.length);
  };

  // 获取api服务包列表
  const getApisList = () => {
    setLoading(true);
    let params = {
      page: pageNumber,
      size: pageSize,
    };
    if (statuCode !== "all") {
      params.api_status = statuCode;
    }
    if (serviceName !== "") {
      params.api_name = serviceName;
    }

    projectApi.getApisList(params, orderList).then((res) => {
      if (res.code === 200) {
        setDevPackageList(res.data.items);
        setTotal(res.data.total);
        setLoading(false);
        setPlainOptionList(res.data.items);
      } else {
        setPlainOptionList([]);
        setDevPackageList([]);
        setTotal(0);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getApisList();
  }, [statuCode, serviceName, pageNumber, pageSize, orderList]);

  // 分页
  const pageChange = (page, size) => {
    setPageNumber(page);
    setPageSize(size);
  };

  // 排序 正序
  const sortOrder = (item, id) => {
    setOrderList({
      orders: [
        {
          order_name: item.key,
          order_by: id,
        },
      ],
    });
    setPageNumber(1);
  };

  // 编辑服务
  const modifyDevService = async (data) => {
    setDialogDrawerVisible(true);
    setRelease(false);
    await setDefaultData(data);
    await setDialogLoading(false);
  };

  // 提交审核发布
  const submitCheck = (data) => {
    setSubmitCheckData(data);
    setDefaultData(data);
    setDialogDrawerVisible(true);
    setRelease(true);
    setDialogLoading(false);
  };

  // 废弃
  const batchDelete = (value) => {
    MessageModal({
      type: "warning",
      description: "确定要废弃API服务吗？",
      message: "审核通过后API将从不再提供用户使用",
      title: "废弃API服务",
      okText: "废弃",
      onOk: () => {
        projectApi.abandonServiceDev(value).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getApisList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 取消废弃
  const cancelDelete = (value) => {
    MessageModal({
      description: "确定要取消废弃API服务吗？",
      message: "审核通过后将恢复APi服务",
      title: "取消废弃API服务",
      okText: "取消废弃",
      onOk: () => {
        // projectApi.abandonServiceDev(value).then((res) => {
        //   if (res.code === 200) {
        //     message.success(res.message);
        //     getApisList();
        //   } else {
        //     message.error(res.message);
        //   }
        // });
      },
    });
  };

  // 确认录入(提交审核)
  const dialogDrawerSuccess = (rn, value) => {
    if (release) {
      reviewPkg(rn, value);
    } else {
      modifyServiceDev(rn);
    }
  };

  // 确认修改请求
  const modifyServiceDev = (data) => {
    projectApi.modifyServiceDev(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        getApisList();
        setDialogDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 提交审核
  const reviewPkg = (rn, data) => {
    projectApi.reviewPkg(rn, data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        getApisList();
        setDialogDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 获取版本
  const getSerVerNoList = (id) => {
    projectApi.getSerVerNoList(id).then((res) => {
      if (res.code === 200) {
        res?.data?.length === 0
          ? setVersonList([{ api_pkg_ver_id: 0, ver_no: "当前" }])
          : setVersonList(res.data);
      }
    });
  };

  // 查看详情信息
  const getDetailInfo = (id, Info, flag) => {
    if (flag) {
      getSerVerNoList(Info?.api_id);
      Info?.last_ver_no === null
        ? setVerId("当前")
        : setVerId(Info?.last_ver_no);
    }

    let params = {
      api_id: Info.api_id,
    };
    if (id !== null) {
      params.ver_no = id;
    }
    setDetailDrawerLoading(true);
    setDetailDrawerVisible(true);
    projectApi.getSerDetail(params).then((res) => {
      if (res.code === 200) {
        setDetailInfo({ ...Info, ...res.data });
        setDetailDrawerLoading(false);
      } else {
        setDetailInfo({});
        setDetailDrawerLoading(false);
      }
    });
  };

  return (
    <div className="ServiceDevContent">
      {/* 检索区域 */}
      <div className="topBox">
        <div className="searchBox">
          <div className="searchItem">
            <div className="label" style={{ marginLeft: "6px" }}>
              状态 :
            </div>
            <div className="optionsBox">
              {statuDataType?.map((item, index) => (
                <div
                  key={index}
                  className="optionItem"
                  onClick={() => {
                    setStatuCode(item);
                  }}
                  style={{
                    backgroundColor: item === statuCode ? "#006AB2" : null,
                    color: item === statuCode ? "#fff" : null,
                  }}
                >
                  {newstatuDataType?.map((value) => value[item])}
                </div>
              ))}
            </div>
          </div>
          <div className="searchItem">
            <div className="label">API服务名称 :</div>
            <div className="optionsBox">
              <Input
                bordered={false}
                allowClear
                style={{
                  borderBottom: "1px solid #999999",
                  width: "280px",
                }}
                onChange={(e) => {
                  if (e.target.value === "") setServiceName("");
                }}
                onPressEnter={(e) => {
                  setServiceName(e.target.value);
                }}
                onBlur={(e) => {
                  setServiceName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="buttonBox">
          <Button
            type="primary"
            onClick={() => {
              const token = getToken();
              const url = `http://data-service-api-dev.touzhiwang.cn/data_service/magic/web/index.html?token=${token}`;
              window.open(url, "_blank");
            }}
          >
            服务开发
          </Button>

          <Button
            type="primary"
            onClick={() => {
              if (checkedList.length !== 0) {
                setDialogLoading(true);
                submitCheck(checkedList);
              } else if (checkedList.length === 0) {
                message.info("请选择需要审核的服务");
              }
            }}
          >
            批量审核
          </Button>
        </div>
      </div>

      {/* 排序 */}
      <div className="filterItem">
        <div className="optionBoxs">
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            disabled={
              !(statuCode === "TO_BE_RELEASED" || statuCode === "DISCARDED")
            }
          >
            全选
          </Checkbox>
          {orderDataList.map((item) => (
            <div key={item.key} className="optionItem">
              {item.label}
              <div className="iconBox">
                <CaretUpOutlined
                  className="upIcon"
                  onClick={() => {
                    sortOrder(item, "asc");
                    setUpOrderCode(item);
                    setDownOrderCode({});
                  }}
                  style={{ color: item === upOrderCode ? "#0068B2" : null }}
                />
                <CaretDownOutlined
                  className="downIcon"
                  onClick={() => {
                    sortOrder(item, "desc");
                    setDownOrderCode(item);
                    setUpOrderCode({});
                  }}
                  style={{ color: item === downOrderCode ? "#0068B2" : null }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 展示 */}
      <div className="cardBox">
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{ height: "100%", width: "100%" }}
        >
          <CheckboxGroup
            onChange={checkChange}
            value={checkedList}
            style={{ width: "100%" }}
          >
            {loading ? (
              <MySpin></MySpin>
            ) : devPackageList?.length > 0 ? (
              devPackageList?.map((item, index) => (
                <ServiceDevCard
                  checkedList={checkedList}
                  defaultData={item}
                  key={index}
                  batchDelete={batchDelete}
                  cancelDelete={cancelDelete}
                  modifyDevService={modifyDevService}
                  submitCheck={submitCheck}
                  setDialogLoading={setDialogLoading}
                  getDetailInfo={getDetailInfo}
                ></ServiceDevCard>
              ))
            ) : (
              <div className="noData">
                <Empty></Empty>
              </div>
            )}
          </CheckboxGroup>
        </Scrollbars>
      </div>
      {loading ? null : total >= 1 ? (
        <div className="paginationBox">
          <Pagination
            onChange={pageChange}
            className="myPagination"
            pageSize={pageSize}
            current={pageNumber}
            total={total}
            showTotal={(total) => `共 ${total} 条`}
            showSizeChanger={true}
            showQuickJumper
            pageSizeOptions={[10, 20, 50, 100]}
          />
        </div>
      ) : null}
      <DialogDrawer
        drawerTitle={release ? "提交发布" : "修改"}
        okText={release ? "提交发布" : "保存"}
        cancelText="取消"
        onOk={dialogDrawerSuccess}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
        defaultData={defaultData}
        release={release}
        loading={DialogLoading}
        submitCheckData={submitCheckData}
      ></DialogDrawer>

      <DevDetailDrawer
        VersoList={VersonList}
        visible={DetailDrawerVisible}
        defaultData={DetailInfo}
        onCancel={() => {
          setDetailDrawerVisible(false);
        }}
        loading={DetailDrawerLoading}
        getDetailInfo={getDetailInfo}
        verId={verId}
        setVerId={setVerId}
      ></DevDetailDrawer>
    </div>
  );
};
export default ServiceDev;
