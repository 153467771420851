import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { Button, Form, Input, Select, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import HeaderTitle from "../../../../../components/HeaderTitle";
import MessageModal from "../../../../../components/MessageModal";
import DropdownSvg from "../../../../../assets/下拉.svg";
import Scrollbars from "react-custom-scrollbars";
import projectApi from "../../../../../apis/project";
import { useSelector } from "react-redux";
import { debounce } from "../../../../../unit/unit";
import MySpin from "../../../../../components/MySpin";
import FieldTable from "../FieldTable";
import VerifyModal from "../VerifyModal";

const { Option } = Select;
const { TextArea } = Input;
const DialogDrawer = (props) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);

  // 分区类型
  const ptnType = dicCodeInfoList?.DG_PTN_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  // 数据分层主题域映射
  const layerSubList = useSelector((state) => state.Project.layerSubList);

  const {
    setDialogDrawerVisible,
    addNew,
    subId, // 主题域最新的版本
    layerList,
    DBType, // 存储类型
    addDesign,
    modelTableList,
    colNameList,
    setColNameList,
    modifyId, // 模型修改对应的id
    modifyLoding,
    tableSuffixList, // 表名后缀
    setModifyLoding,
    copiedFlag,
  } = props;

  const [modelSwitch, setModelSwitch] = useState(true); // 模型类型选择切换
  const [subList, setSubList] = useState([]); // 主题域列表
  const [subData, setSubData] = useState([]); // 主题域列表(模糊搜索使用)
  const [layerCode, setLayerCode] = useState(null); // 数据分层选择
  const [formChiName, setFormChiName] = useState(""); // 表中文名
  const [formCmnt, setFormCmnt] = useState(""); // 表描述内容
  const [subCode, setSubCode] = useState(null); // 主题域选择
  const [ddlValue, setDdlValue] = useState(""); // ddl输入内容
  const [tableLoding, setTableLoding] = useState(true); // ddl转表格loading
  const [ddlLoding, setDdlLoding] = useState(false); // 表格loading转ddl
  const [ddlTableList, setDdlTableList] = useState({
    columns: [],
    indexes: [],
    ptns: [],
  });

  const [taleName, setTaleName] = useState("");
  const [taleCode, setTaleCode] = useState(null);
  const [colLayerCode, setColLayerCode] = useState(""); // 表名匹配数据分层字段
  const [colsubCode, setColSubCode] = useState(""); // 表名匹配主题域字段

  const [ptnCode, setPtnCode] = useState(null); // 分区类型选择
  const [ptnName, setPtnname] = useState(null); // 分区字段选择

  const [columsSource, setColumsSource] = useState([]);
  const [indexSource, setIndexSource] = useState([]);
  const [partSource, setPartSource] = useState([]);
  // 获取主题域已发布标准
  const getPublishedList = (layerCode) => {
    projectApi
      .getSubPublishedList(
        { ver_no: subId, data_layer_id: layerCode },
        { orders: [] }
      )
      .then((res) => {
        if (res.code === 200) {
          const data = res.data.items.map((item) => {
            return {
              sub_id: item.sub_id,
              sub_name: item.sub_name,
              sub_eng_abbr: item.sub_eng_abbr?.toLowerCase(),
            };
          });
          let newArr = [];
          let arrId = [];
          for (let item of data) {
            if (arrId.indexOf(item["sub_id"]) === -1) {
              arrId.push(item["sub_id"]);
              newArr.push(item);
            }
          }
          setSubList(newArr);
          setSubData(newArr);
        } else {
          setSubList([]);
          setSubData([]);
        }
      });
  };

  // 修改和复制的时候的处理
  const handleTableListUpdate = (tableList) => {
    setLayerCode(tableList?.base_info?.data_layer_id);
    setSubCode(tableList?.base_info?.sub_id);
    setFormChiName(tableList?.base_info?.table_cmnt);
    setFormCmnt(tableList?.base_info?.metadata_desc);

    if (tableList?.ptns?.length !== 0) {
      setPtnCode(tableList?.ptns?.[0]?.ptn_type_code);
      setPtnname(tableList?.ptns?.[0]?.ptn_col_name);
    } else {
      setPtnCode(null);
      setPtnname(null);
    }

    form.setFieldsValue({
      data_layer_id: tableList?.base_info?.data_layer_id,
      sub_id: tableList?.base_info?.sub_id,
      table_cmnt: tableList?.base_info?.table_cmnt,
      metadata_desc: tableList?.base_info?.metadata_desc,
    });

    setDdlTableList(tableList);
    setTableLoding(false);
  };

  // 修改和复制的时候的处理
  useEffect(() => {
    setTableLoding(true);
    if (addNew === false || copiedFlag === true) {
      handleTableListUpdate(modelTableList);
    }
    setTableLoding(false);
  }, [modelTableList, copiedFlag, addNew]);

  useEffect(() => {
    form.setFieldsValue({
      ptn_type_code: ptnCode,
      ptn_col_name: ptnName,
    });
  }, [modelSwitch, ptnCode, ptnName]);

  useEffect(() => {
    if (layerCode !== null && layerCode !== undefined) {
      getPublishedList(layerCode);
    }
  }, [layerCode, subCode]);

  // 表名数据转换
  useEffect(() => {
    if (layerCode !== null && subCode !== null && subList?.length !== 0) {
      setColLayerCode(
        layerList?.find((item) => item.data_layer_id === layerCode)
          ?.data_layer_eng_abbr
      );
      setColSubCode(
        subList?.find((item) => item.sub_id === subCode)?.sub_eng_abbr
      );
    }
    if (addNew === false || copiedFlag) {
      // 表名前半部分
      const layerInfo = layerList?.find(
        (item) => item.data_layer_id === layerCode
      )?.data_layer_eng_abbr;
      const subInfo = subList?.find(
        (item) => item.sub_id === subCode
      )?.sub_eng_abbr;

      const tableName = modelTableList?.base_info?.table_name;
      if (layerInfo !== undefined && subInfo !== undefined) {
        const data = tableName
          ?.replace(`${layerInfo}_`, "")
          ?.replace(`${subInfo}_`, "");
        setTaleName(data);
        form.setFieldsValue({
          table_name: data,
        });
        setModifyLoding(false);
      }
    }
  }, [layerCode, subCode, subList]);

  // 获取ddl模式转表格信息(新增)
  const parseDesignDdl = () => {
    projectApi
      .parseDesignDdl({ ddl: ddlValue })
      .then((res) => {
        if (res.code === 200) {
          setDdlTableList(res.data);
          setTableLoding(false);
          setColNameList(
            res?.data?.columns.reduce(
              (pre, info) => [...pre, info?.col_name],
              []
            )
          );
          if (res.data?.ptns?.length !== 0) {
            setPtnCode(res.data?.ptns?.[0]?.ptn_type_code);
            setPtnname(res.data?.ptns?.[0]?.ptn_col_name);
          }
        } else {
          message.error(res.message);
          setDdlTableList({});
          setTableLoding(false);
        }
      })
      .catch((res) => {
        setTableLoding(false);
      });
  };
  useEffect(() => {
    setColNameList(
      ddlTableList?.columns?.reduce((pre, info) => [...pre, info?.col_name], [])
    );
  }, [ddlTableList]);
  useEffect(() => {
    if (ddlValue !== "" && addNew) {
      setTableLoding(true);
      parseDesignDdl();
    }
  }, [ddlValue]);

  // 模式切换清空值
  useEffect(() => {
    if (modelSwitch === false && addNew === false) {
      setDdlLoding(true);
      modifyDdl();
    }
    if (
      modelSwitch === false &&
      addNew &&
      subCode !== null &&
      layerCode !== null
    ) {
      setDdlLoding(true);
      tableTransferDdl();
    }
  }, [modelSwitch]);

  // 模型-表格转ddl（修改）
  const modifyDdl = () => {
    let ptnData = partSource?.reduce(
      (cur, pre) => [
        ...cur,
        { ptn_name: pre.ptn_name, ptn_value: pre.ptn_value },
      ],
      []
    );
    projectApi
      .modifyDdl({
        base_info: {
          data_layer_id: layerCode,
          sub_id: subCode,
          table_name: `${colLayerCode}_${colsubCode}_${taleName}`,
          table_cmnt: formChiName,
          metadata_desc: formCmnt,
          db_type_code: "POSTGRESQL",
          table_id: modelTableList?.base_info?.table_id,
        },
        columns: columsSource,
        indexes: indexSource,
        ptns:
          partSource?.length !== 0
            ? [
                {
                  ptn_type_code: ptnCode,
                  ptn_col_name: ptnName,
                  ptn_def_id: ddlTableList?.ptns[0]?.ptn_def_id,
                  ptn_cols: ptnData,
                },
              ]
            : ptnCode !== null && ptnName !== null
            ? [
                {
                  ptn_type_code: ptnCode,
                  ptn_col_name: ptnName,
                  ptn_def_id: ddlTableList?.ptns[0]?.ptn_def_id,
                },
              ]
            : [],
      })
      .then((res) => {
        if (res.code === 200) {
          setDdlValue(res.data);
          form.setFieldsValue({
            ddl: res.data,
          });
          setDdlLoding(false);
        } else {
          message.error(res.message);
          setDdlLoding(false);
          setDdlValue("");
        }
      })
      .catch((res) => {
        setDdlLoding(false);
      });
  };

  // 模型-表格转ddl（新增）
  const tableTransferDdl = () => {
    let ptnData = partSource?.reduce(
      (cur, pre) => [
        ...cur,
        { ptn_name: pre.ptn_name, ptn_value: pre.ptn_value },
      ],
      []
    );
    projectApi
      .tableTransferDdl({
        base_info: {
          data_layer_id: layerCode,
          sub_id: subCode,
          table_name: `${colLayerCode}_${colsubCode}_${taleName}`,
          table_cmnt: formChiName,
          metadata_desc: formCmnt,
          db_type_code: "POSTGRESQL",
          table_id: modelTableList?.base_info?.table_id,
        },
        columns: columsSource,
        indexes: indexSource,
        ptns:
          partSource?.length !== 0
            ? [
                {
                  ptn_type_code: ptnCode,
                  ptn_col_name: ptnName,
                  ptn_cols: ptnData,
                },
              ]
            : ptnCode !== null && ptnName !== null
            ? [
                {
                  ptn_type_code: ptnCode,
                  ptn_col_name: ptnName,
                },
              ]
            : [],
      })
      .then((res) => {
        if (res.code === 200) {
          setDdlValue(res.data);
          form.setFieldsValue({
            ddl: res.data,
          });
          setDdlLoding(false);
        } else {
          message.error(res.message);
          setDdlLoding(false);
          setDdlValue("");
        }
      })
      .catch((res) => {
        setDdlLoding(false);
      });
  };

  // 判断是否有重复的col_name给予提示
  const hasDuplicateColName = (columnsSource) => {
    const colNamesSet = new Set();
    const duplicateColNames = [];

    for (const column of columnsSource) {
      if (colNamesSet.has(column.col_name)) {
        duplicateColNames.push(column.col_name);
      } else {
        colNamesSet.add(column.col_name);
      }
    }

    if (duplicateColNames.length > 0) {
      return {
        flag: true,
        col_names: duplicateColNames.join("、"),
      };
    } else {
      return {
        flag: false,
        col_names: null,
      };
    }
  };
  // 判断表名是否重复
  const checkTableExists = async (rule, value) => {
    const name = `${colLayerCode}_${colsubCode}_${value}`;
    if (!value || (name === modelTableList?.base_info?.table_name && !addNew)) {
      return Promise.resolve();
    }

    try {
      const res = await projectApi.TableDesignExists(name);
      if (res.code === 200 && res.data > 0) {
        MessageModal({
          type: "warning",
          description: `存在重复的表名: ${name}`,
          title: "重复操作",
          okText: "确认",
        });
        return Promise.reject(`存在重复的表名`);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject("验证失败，请稍后重试");
    }
  };

  const onFinish = (value) => {
    form.validateFields();
    const data = columsSource.reduce(
      (cur, pre) => [
        ...cur,
        {
          col_name: pre.col_name,
          col_cmnt: pre.col_desc,
          col_data_type_code: pre.col_type_code,
          col_length: pre.col_length,
          col_scale: pre.col_scale,
        },
      ],
      []
    );

    const result = hasDuplicateColName(data);

    if (result.flag) {
      // 存在重复字段名禁止下一步操作
      MessageModal({
        type: "warning",
        description: `存在重复的字段名: ${result.col_names}`,
        title: "重复操作",
        okText: "确认",
        onOk: () => {},
      });
      return;
    } else {
      // 批量校验是否符合数据元标准
      projectApi.verifyEleStandard(data).then((res) => {
        if (res.code === 200) {
          // 批量处理需要校验的数据
          const data = columsSource.reduce(
            (cur, pre) => [...cur, { ...pre, status: res.data[pre.col_name] }],
            []
          );
          setColumsSource(data);
          const dataInfo = {
            ...value,
            table_name: `${colLayerCode}_${colsubCode}_${taleName}`,
            // table_name: `${colLayerCode}_${colsubCode}_${taleName}_${taleCode}`,
          };

          // 未通过校验字段统计
          let falseKeys = [];
          for (let key in res.data) {
            if (res.data[key] === false) {
              falseKeys.push(key);
            }
          }
          // console.log(falseKeys, res.data);
          if (!Object.values(res.data).includes(false)) {
            saveRequest(dataInfo);
          } else {
            message.error("数据元校验存在未通过");
            VerifyModal({
              description: "存在未通过数据元校验字段确认要保存吗？",
              messageInfo: `包含${falseKeys.length}条数据未通过数据元校验!`,
              title: "保存模型",
              okText: "保存",
              data: falseKeys,
              columsSource,
              FieldType: dicCodeInfoList?.DG_DATA_TYPE,
              onOk: () => {
                saveRequest(dataInfo);
              },
            });
          }
        } else {
          message.error("程序报错，请联系工作人员");
        }
      });
    }
  };

  // 保存调用请求
  const saveRequest = (data) => {
    if (modelSwitch && addNew) {
      // 新增模型
      addDesign(
        {
          ...data,
          columns: columsSource,
          indexes: indexSource,
          ptns: partSource,
        },
        ddlTableList
      );
    } else if (modelSwitch && addNew === false) {
      // 修改保存模型
      addDesign(
        {
          ...data,
          table_id: modifyId,
          columns: columsSource,
          indexes: indexSource,
          ptns: partSource,
        },
        ddlTableList
      );
    }
  };

  return modifyLoding ? (
    <MySpin></MySpin>
  ) : (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={5000}
      style={{
        height: `100%`,
        width: "100%",
      }}
      className="ModelDesignDrawer"
    >
      <CloseOutlined
        className="CloseOutlined"
        onClick={() => setDialogDrawerVisible(false)}
      />
      <Form
        form={form}
        autoComplete="off"
        className="dialogDrawerForm"
        preserve={false}
        onFinish={onFinish}
        ref={formRef}
      >
        <HeaderTitle
          title="基本信息"
          extra={
            <div className="selectBtn">
              <Button
                style={{
                  background: modelSwitch ? "#016DB4" : "#D4D4D4",
                  borderColor: modelSwitch ? "#016DB4" : "#D4D4D4",
                }}
                onClick={() => setModelSwitch(true)}
              >
                图形界面模式
              </Button>
              <Button
                style={{
                  background: modelSwitch ? "#D4D4D4" : "#016DB4",
                  borderColor: modelSwitch ? "#D4D4D4" : "#016DB4",
                }}
                onClick={() => {
                  setModelSwitch(false);
                }}
              >
                DDL模式
              </Button>
            </div>
          }
        ></HeaderTitle>

        <Form.Item
          name="data_layer_id"
          label="数据分层"
          rules={[
            {
              required: true,
              message: "请选择数据分层",
            },
          ]}
        >
          <Select
            placeholder="请选择"
            showSearch
            allowClear
            showArrow="true"
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            style={{ width: "368px" }}
            onChange={(value) => {
              setLayerCode(value);
              setSubCode(null);
              form.setFieldsValue({
                sub_id: null,
              });
              setSubData([]);
            }}
            options={layerList?.map((item) => ({
              label: item.data_layer_name,
              value: item.data_layer_id,
            }))}
          ></Select>
        </Form.Item>

        <Form.Item
          label="主题域"
          name="sub_id"
          rules={[
            {
              required: true,
              message: "请选择主题域",
            },
          ]}
        >
          <Select
            placeholder="请选择"
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.props.children
                ?.toLowerCase()
                ?.indexOf(input.toLowerCase()) >= 0
            }
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            onChange={(value) => {
              setSubData(subList);
              setSubCode(value);
            }}
            onSearch={debounce((e) => {
              if (e !== "") {
                setSubData(subData.filter((item) => item.sub_name.includes(e)));
              } else {
                setSubData(subList);
              }
            }, 500)}
            style={{ width: "368px" }}
          >
            {subData?.map((item) => {
              return (
                <Option value={item.sub_id} key={item.sub_id}>
                  {item.sub_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="存储类型"
          name="db_type_code"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "请选择存储类型",
            },
          ]}
          initialValue="POSTGRESQL"
        >
          <Select
            placeholder="请选择"
            allowClear
            showSearch={true}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            disabled
            style={{ width: "368px" }}
          >
            {DBType?.map((item) => {
              return (
                <Option value={item} key={item}>
                  {dicCodeInfoList?.DG_DB_TYPE?.map((value) => value[item])}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="表中文名"
          name="table_cmnt"
          rules={[
            {
              required: true,
              message: "请输入表中文名，不超过100个字符",
            },
            {
              pattern: /^^(.|\n){0,100}$/,
              message: "表中文名不超过100个字符",
            },
          ]}
        >
          <Input
            allowClear
            placeholder="1~100个字符"
            style={{ width: "368px" }}
            onChange={(e) => setFormChiName(e.target.value)}
          />
        </Form.Item>
        <span className="symbolRed">*</span>
        <Form.List>
          {() => (
            <>
              <Form.Item
                label="表名"
                name="table_name_code"
                // style={{ marginLeft: "22px" }}
              >
                {(colLayerCode !== "") & (colsubCode !== "") ? (
                  <span style={{ marginRight: "4px" }}>
                    {`${colLayerCode}_${colsubCode}_`}
                  </span>
                ) : null}
              </Form.Item>

              <Form.Item
                label=""
                name="table_name"
                style={{ marginLeft: "-50px" }}
                rules={[
                  {
                    required: true,
                    // message: `请输入表名，不超过${
                    //   64 - colLayerCode.length - colsubCode.length - 2
                    // }个字符`,
                    message: `请输入表名，不超过64个字符`,
                  },
                  {
                    pattern: /^[a-z0-9_]*$/,
                    message: "只允许输入小写字符、下划线或数字",
                  },
                  {
                    validator: (_, value) => {
                      const maxTableNameLength =
                        64 - colLayerCode.length - colsubCode.length - 2;
                      if (value && value.length > maxTableNameLength) {
                        return Promise.reject(
                          // `表名不超过${maxTableNameLength}个字符`
                          `表名不超过64个字符`
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                  {
                    validator: checkTableExists,
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="英文字符、数字或符号"
                  style={{ width: "280px", marginRight: "4px" }}
                  onChange={(e) => {
                    setTaleName(e.target.value);
                  }}
                  // onPressEnter={handleEnterPress}
                  // onBlur={handleEnterPress}
                />
              </Form.Item>

              {/* <Form.Item
                    label=""
                    name="table_code"
                    style={{ marginLeft: "-50px" }}
                    rules={[
                      {
                        required: true,
                        message: "请选择表名后缀",
                      },
                    ]}
                  >
                    <Select
                      placeholder="请选择"
                      allowClear
                      showSearch={true}
                      value={taleCode}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "96px" }}
                      onChange={(e) => {
                        setTaleCode(e);
                        form.setFieldsValue({
                          table_cmnt: `${colLayerCode}${colsubCode}${taleName}${e}`,
                        });
                      }}
                    >
                    {tableSuffixList?.map((item,index) =>  <Option value="jack" key={index
                    }>{}</Option>)}
                    
                      
                    </Select>
                  </Form.Item> */}
            </>
          )}
        </Form.List>

        <Form.Item
          name="metadata_desc"
          label="描述"
          style={{ marginLeft: "40px" }}
        >
          <TextArea
            allowClear
            autoSize={{ minRows: 3 }}
            style={{
              width: "940px",
            }}
            onChange={(e) => setFormCmnt(e.target.value)}
          />
        </Form.Item>
        {modelSwitch ? (
          <>
            <HeaderTitle title="字段设计"></HeaderTitle>
            <div style={{ width: "93%" }}>
              <FieldTable
                title="字段设计"
                ddlTableList={ddlTableList}
                ddlLoding={tableLoding}
                columsSource={columsSource}
                setColumsSource={setColumsSource}
                setDdlTableList={setDdlTableList}
              ></FieldTable>
            </div>
            <HeaderTitle title="索引"></HeaderTitle>
            <div style={{ width: "70%" }}>
              <FieldTable
                title="索引"
                ddlTableList={ddlTableList}
                ddlLoding={tableLoding}
                colNameList={colNameList}
                indexSource={indexSource}
                setIndexSource={setIndexSource}
                setDdlTableList={setDdlTableList}
                addNew={addNew}
                modifyId={modifyId}
                colLayerCode={colLayerCode}
                colsubCode={colsubCode}
                taleName={taleName}
              ></FieldTable>
            </div>
            <HeaderTitle title="分区"></HeaderTitle>

            {ddlTableList && ddlTableList?.ptns ? (
              <>
                <Form.Item
                  label="分区类型"
                  name="ptn_type_code"
                  initialValue={ddlTableList?.ptns[0]?.ptn_type_code}
                >
                  <Select
                    placeholder="请选择"
                    showSearch
                    allowClear
                    showArrow="true"
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "368px" }}
                    onChange={(e) => {
                      if (e === undefined) {
                        setPtnCode(null);
                      } else {
                        setPtnCode(e);
                      }
                    }}
                  >
                    {ptnType?.map((item) => {
                      return (
                        <Option value={item} key={item}>
                          {dicCodeInfoList?.DG_PTN_TYPE?.map(
                            (value) => value[item]
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="分区字段"
                  name="ptn_col_name"
                  initialValue={ddlTableList?.ptns[0]?.ptn_col_name}
                >
                  <Select
                    placeholder="请选择"
                    showSearch
                    allowClear
                    showArrow="true"
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "368px" }}
                    onChange={(e) => {
                      if (e === undefined) {
                        setPtnname(null);
                      } else {
                        setPtnname(e);
                      }
                    }}
                    options={colNameList?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  ></Select>
                </Form.Item>
                <div style={{ width: "50%" }}>
                  <FieldTable
                    title="分区"
                    ptnData={
                      ddlTableList?.ptns?.length !== 0
                        ? ddlTableList?.ptns[0]?.ptn_cols
                        : []
                    }
                    ddlTableList={ddlTableList}
                    ddlLoding={tableLoding}
                    partSource={partSource}
                    setPartSource={setPartSource}
                    setDdlTableList={setDdlTableList}
                  ></FieldTable>
                </div>
              </>
            ) : null}
          </>
        ) : (
          <>
            <HeaderTitle title="DDL定义"></HeaderTitle>
            {ddlLoding ? (
              <MySpin></MySpin>
            ) : (
              <Form.Item name="ddl" style={{ marginLeft: "10px" }}>
                <TextArea
                  allowClear
                  style={{
                    width: "1200px",
                    color: "#006AB2",
                  }}
                  autoSize={{ minRows: 20 }}
                  onBlur={(e) => setDdlValue(e.target.value)}
                />
              </Form.Item>
            )}
          </>
        )}

        {modelSwitch ? (
          <div className="drawerFooterBtnBox">
            <Button
              onClick={() => {
                form.submit();
              }}
              style={{
                background: "#016DB4",
                borderColor: "#016DB4",
                color: "#FFFFFF",
              }}
            >
              {addNew ? "新建" : "保存"}
            </Button>
            <Button
              onClick={() => {
                setDialogDrawerVisible(false);
              }}
              style={{
                background: "#fff",
              }}
            >
              取消
            </Button>
          </div>
        ) : null}
      </Form>
    </Scrollbars>
  );
};

export default DialogDrawer;
