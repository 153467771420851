import "./index.scss";
import { Table, Pagination } from "antd";
import { useState, useEffect } from "react";

import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";
import MySpin from "../../../../../components/MySpin";
import LoadingSvg from "../../../../../assets/loading.svg";
import { ReactSVG } from "react-svg";
const DetailTable = (props) => {
  const {
    defaultData,
    changePagination,
    changeOrders,
    tableLoading,
    AllApiList,
  } = props;

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);

  const data = defaultData?.items;
  const columsSource = [
    {
      title: "应用名称",
      dataIndex: "app_id",
      sorter: true,
      render: (data, info) => {
        return AllApiList[info?.app_id]?.app_name;
      },
    },
    {
      title: "AppID",
      dataIndex: "app_id",
      sorter: true,
    },
    {
      title: "API服务包",
      dataIndex: "api_pkg_id",
      sorter: true,
      render: (data, info) => {
        return Object.values(AllApiList).find(
          (item) => item?.api_pkg_id === info?.api_pkg_id
        )?.chi_name;
      },
    },
    {
      title: "API服务",
      dataIndex: "api_id",
      sorter: true,
    },
    {
      title: "调用日期",
      dataIndex: "call_time",
      sorter: true,
    },
    {
      title: "调用参数",
      dataIndex: "call_parm",
    },
    {
      title: "返回结果编码",
      dataIndex: "result_code",
    },
    {
      title: "错误日志",
      dataIndex: "fail_log",
    },
  ];

  useEffect(() => {
    setTotal(defaultData?.total);

    setPageNumber(defaultData?.page);
    setPageSize(defaultData?.size);
  }, [defaultData]);

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      changeOrders(sorter);
    }
  };

  return (
    <div className="DetailTable">
      <div className="tableContent">
        <Table
          className="myFormTable"
          pagination={false}
          columns={columsSource}
          dataSource={data}
          rowKey={() => Math.random()}
          onChange={tableChange}
          scroll={{
            y: "calc(50vh - 160px)",
          }}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
          style={{ marginBottom: "8px" }}
          loading={
            tableLoading
              ? {
                  indicator: (
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  ),
                }
              : false
          }
        />
        {total >= 1 ? (
          <div className="paginationBox">
            <Pagination
              className="myPagination"
              showQuickJumper
              total={total}
              current={pageNumber}
              pageSize={pageSize}
              pageSizeOptions={[10, 20, 50, 100]}
              onChange={onChange}
              showTotal={(total) => `共 ${total} 条`}
              showSizeChanger={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DetailTable;
