import "./index.scss";
import { Table, Pagination } from "antd";
import { useState, useEffect } from "react";

import MyEmpty from "../../../../../components/Empty";
import MySpin from "../../../../../components/MySpin";
import LoadingSvg from "../../../../../assets/loading.svg";
import { ReactSVG } from "react-svg";

const ApplicationTable = (props) => {
  const {
    defaultData,
    changePagination,
    tableLoading,
    modifyApp,
    deletItem,
    getAppDetail,
  } = props;

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [apiIsHidden, setApiIsHidden] = useState(false);
  const [secretIsHidden, setSecretIsHidden] = useState(false);
  const [id, setId] = useState("");
  const data = defaultData?.items;

  useEffect(() => {
    setTotal(defaultData?.total);
    setPageSize(10);
    setPageNumber(1);
  }, [defaultData]);

  const columns = [
    {
      title: "应用名称",
      dataIndex: "app_name",
    },
    {
      title: "AppID",
      dataIndex: "app_id",
    },
    {
      title: "API Key",
      dataIndex: "api_key",
      render: (_, info) => {
        return (
          <div className="IsHidden">
            <div>
              {apiIsHidden && id === info?.app_id
                ? info?.api_key
                : "**************"}
            </div>
            <div className="show" onClick={() => setApiIsHidden(!apiIsHidden)}>
              {apiIsHidden && id === info?.app_id ? "隐藏" : "显示"}
            </div>
          </div>
        );
      },
    },
    {
      title: "Secret Key",
      dataIndex: "secret_key",
      render: (_, info) => {
        return (
          <div className="IsHidden">
            <div>
              {secretIsHidden && id === info?.app_id
                ? info?.secret_key
                : "**************"}
            </div>
            <div
              className="show"
              onClick={() => setSecretIsHidden(!secretIsHidden)}
            >
              {secretIsHidden && id === info?.app_id ? "隐藏" : "显示"}
            </div>
          </div>
        );
      },
    },
    {
      title: "创建时间",
      dataIndex: "created_time",
    },
    {
      title: "更新时间",
      dataIndex: "updated_time",
    },
    {
      title: "操作",
      dataIndex: "操作",
      width: "12%",
      render: (_, record) => {
        return (
          <div className="operation" key={record.key}>
            <span
              style={{
                color: "#006AB2",
                marginRight: "16px",
              }}
              onClick={() => getAppDetail(record?.app_id)}
            >
              详情
            </span>
            <span
              style={{
                color: "#006AB2",
                marginRight: "16px",
              }}
              onClick={() => modifyApp(record?.app_id)}
            >
              修改
            </span>
            <span
              style={{
                color: "#006AB2",
              }}
              onClick={() => deletItem(record?.app_id)}
            >
              删除
            </span>
          </div>
        );
      },
    },
  ];

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  return (
    <div className="ApplicationTable">
      {tableLoading ? (
        <MySpin></MySpin>
      ) : (
        <div className="tableContent">
          <Table
            className="myFormTable"
            pagination={false}
            columns={columns}
            dataSource={data}
            rowKey={(record, id) => id}
            scroll={{
              y: "calc(100vh - 355px)",
            }}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
            loading={
              tableLoading
                ? {
                    indicator: (
                      <div style={{ background: "#fff" }}>
                        <ReactSVG
                          src={LoadingSvg}
                          className="anticon-spin anticon myLoadingIcon"
                        />
                      </div>
                    ),
                  }
                : false
            }
            onRow={(record) => {
              return {
                onClick: (event) => setId(record?.app_id),
                onMouseLeave: (event) => setId(""),
              };
            }}
          />
          {total >= 1 ? (
            <div className="paginationBox">
              <Pagination
                className="myPagination"
                showQuickJumper
                current={pageNumber}
                total={total}
                pageSize={pageSize}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={onChange}
                showTotal={(total) => `共 ${total} 条`}
                showSizeChanger={true}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ApplicationTable;
