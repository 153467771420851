import "./index.scss";
import Scrollbars from "react-custom-scrollbars";
import {
  Input,
  Button,
  Pagination,
  message,
  Checkbox,
  Menu,
  Dropdown,
} from "antd";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  DownOutlined,
  PlusOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import DialogDrawer from "./components/DialogDrawer";
import { useSelector } from "react-redux";
import projectApi from "../../../apis/project";
import MessageModal from "../../../components/MessageModal";
import submitIcon from "../../../assets/提交.svg";
import MetaDesignCard from "./components/MetaDesignCard";
import MySpin from "../../../components/MySpin";
import Empty from "../../../components/Empty";
import ReleaseDrawer from "../../../components/ReleaseDrawer";
import DetailDrawer from "./components/DetailDrawer";

const CheckboxGroup = Checkbox.Group;
const orderDataList = [
  { label: "中文名称", key: "table_cmnt" },
  { label: "英文名称", key: "table_name" },
  { label: "创建时间", key: "created_time" },
  { label: "更新时间", key: "updated_time" },
];

const ModelDesign = (props) => {
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);

  // 存储类型
  const DBType = dicCodeInfoList?.DG_DB_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  // 状态
  const statuDataType = dicCodeInfoList?.DG_TABLE_DESG_STAT?.map((item) =>
    Object.keys(item)
  ).flat();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [topLoading, setTopLoading] = useState(false);
  const [modelList, setModelList] = useState([]); // 模型查询结果列表
  const [plainOptionList, setPlainOptionList] = useState([]); // 全选反选绑定数据

  const [layerList, setLayerList] = useState([]); // 数据分层列表
  const [subList, setSubList] = useState([]); // 主题域列表
  const [subId, setSubId] = useState(""); // 主题域标准版本

  const [layerCode, setLayerCode] = useState("all"); // 数据分层选择
  const [subCode, setSubCode] = useState("all"); // 主题域选择
  const [statuCode, setStatuCode] = useState(""); // 状态选择
  const [DBTypeCode, setDBTypeCode] = useState("all"); // 存储类型选择
  const [modeldataName, setModeldataName] = useState(""); // 模型名称
  const [newModeldataName, setNewModeldataName] = useState(""); // 监听模型名称

  const [addNew, setAddNew] = useState(true); // 新增修改模型判断
  const [checkAll, setCheckAll] = useState(false); // 控制全选
  const [checkedList, setCheckedList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true); // 控制反选

  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false); // 新建修改模型
  const [ReleaseDrawerVisible, setReleaseDrawerVisible] = useState(false); // 发布模型
  const [modifyId, setModifyId] = useState(""); // 修改模型对应的id
  const [modifySubCode, setModifySubCode] = useState([]); // 修改模型时提前获取主题域的信息筛选表名

  const [orderList, setOrderList] = useState(["updated_time", "desc"]); // 筛选条件
  const [upOrderCode, setUpOrderCode] = useState({}); // 上排序选择
  const [downOrderCode, setDownOrderCode] = useState({}); // 下排序选择

  const [DetailDrawerVisible, setDetailDrawerVisible] = useState(false); // 查看元数据详情
  const [DetailDrawerLoading, setDetailDrawerLoading] = useState(false); // 查看元数据详情loading
  const [chooseList, setChooseList] = useState([]);
  const [modelMetaInfo, setModelMetaInfo] = useState({}); // 元数据详情（模型id转元数据id，以及版本id）
  const [DetailId, setDetailId] = useState(""); // 元数据详情（模型id转元数据id）
  const [ModelDesignInfo, setModelDesignInfo] = useState({}); // 模型详情数据
  const [metadataVersoList, setMetadataVersonList] = useState([]); // 元数据详情版本列表
  const [relationList, setRelationList] = useState([]); // 血缘关系列表

  const [MetadataStorageList, setMetadataStorageList] = useState([]); // 数据存储列表

  const [modifyLoding, setModifyLoding] = useState(false); // 模型表格信息
  const [modelTableList, setModelTableList] = useState({}); // 模型表格信息
  const [colNameList, setColNameList] = useState([]); // 字段&分区字段对应数据

  const [submitModelDesignData, setSubmitModelDesignData] = useState([]); // 提交审核模型信息

  const [tableSuffixList, setTableSuffixList] = useState([]); // 表名后缀列表
  const [copiedFlag, setCopiedFlag] = useState(false); // 复制点击判断
  // const [flag, setFlag] = useState({}); // 是否选择了数据行键定义

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: <div style={{ marginLeft: "2px" }}>废弃</div>,
          icon: <DeleteFilled style={{ color: "#787878" }} />,
          onClick: () => {
            if (checkedList.length === 0) {
              message.info("请选择需要废弃的模型");
            } else if (
              checkedList?.every(
                (item) =>
                  item?.chg_type_code === "UPDATE" ||
                  item?.desg_stat_code === "RELEASED"
              )
            ) {
              const data = checkedList?.map((item) => item.table_id);
              batchDelete(data);
            } else {
              message.info("选中模型包含不允许废弃！");
            }
          },
        },
        {
          key: "2",
          label: <div>提交审核</div>,
          icon: <ReactSVG src={submitIcon} style={{ marginTop: "6px" }} />,
          onClick: () => {
            if (checkedList.length === 0) {
              message.info("请选择需要审核的模型");
            } else if (
              checkedList?.every(
                (item) =>
                  item?.desg_stat_code === "IN_DESIGN" ||
                  item?.desg_stat_code === "DISCARDED"
              )
            ) {
              setSubmitModelDesignData(checkedList);
              setReleaseDrawerVisible(true);
            } else {
              message.info("选中模型审核条件不过关！");
            }
          },
        },
      ]}
    />
  );

  // 数据存储列表查询
  const getMetadataStorage = () => {
    projectApi.getMetadataStorage().then((res) => {
      if (res.code === 200) {
        setMetadataStorageList(res.data.items);
      } else {
        setMetadataStorageList([]);
      }
    });
  };
  useEffect(() => {
    getMetadataStorage();
  }, []);

  // 获取数据分层标准版本
  const getLayerVersonList = () => {
    projectApi
      .getVersonList(
        { dg_std_type: "DATA_LAYER" },
        { orders: [{ order_name: "std_ver_id", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          if (res.data.total !== 0) {
            getLayerPublishedList(res.data.items[0].ver_no);
          }
        }
      });
  };

  // 获取数据分层已发布标准
  const getLayerPublishedList = (id) => {
    projectApi
      .getLayerPublishedList({ ver_no: id }, { orders: [] })
      .then((res) => {
        if (res.code === 200) {
          const data = res.data.items.map((item) => {
            return {
              data_layer_id: item.data_layer_id,
              data_layer_name: item.data_layer_name,
              data_layer_eng_abbr: item.data_layer_eng_abbr?.toLowerCase(),
            };
          });
          let newArr = [];
          let arrId = [];
          for (let item of data) {
            if (arrId.indexOf(item["data_layer_id"]) === -1) {
              arrId.push(item["data_layer_id"]);
              newArr.push(item);
            }
          }
          setLayerList(newArr);
          // setLayerCode(newArr[0].data_layer_id);
          setTopLoading(false);
        } else {
          setLayerList([]);
          setTopLoading(false);
        }
      });
  };

  // 获取主题域标准版本
  const getVersonList = () => {
    projectApi
      .getVersonList(
        { dg_std_type: "SUB" },
        { orders: [{ order_name: "std_ver_id", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          if (res.data.total !== 0) {
            setSubId(res.data.items[0].ver_no);
          }
        }
      });
  };

  // 获取主题域已发布标准
  const getPublishedList = (id) => {
    projectApi
      .getSubPublishedList(
        { ver_no: subId, data_layer_id: layerCode },
        { orders: [] }
      )
      .then((res) => {
        if (res.code === 200) {
          const data = res.data.items.map((item) => {
            return {
              sub_id: item.sub_id,
              sub_name: item.sub_name,
            };
          });
          let newArr = [];
          let arrId = [];
          for (let item of data) {
            if (arrId.indexOf(item["sub_id"]) === -1) {
              arrId.push(item["sub_id"]);
              newArr.push(item);
            }
          }
          setSubList(newArr);
          // setSubCode(newArr[0].sub_id);
        } else {
          setSubList([]);
        }
      });
  };

  // 获取表名后缀
  const getTableSuffix = () => {
    projectApi.getTableSuffix().then((res) => {
      if (res.code === 200) {
        setTableSuffixList(res.data);
      } else {
        setTableSuffixList([]);
      }
    });
  };

  useEffect(() => {
    setTopLoading(true);
    getLayerVersonList(); // 获取数据分层最新标准
    getVersonList(); // 获取主题域最新标准
    getTableSuffix(); // 获取表名后缀
  }, []);

  useEffect(() => {
    if (layerCode !== "all") getPublishedList();
  }, [layerCode]);

  // 模型查询
  const getModeldataList = () => {
    setLoading(true);
    let params = {
      page: pageNumber,
      size: pageSize,
      order_list: orderList.toString(),
    };
    if (layerCode !== "all") {
      params.data_layer_id = layerCode;
    }
    if (subCode !== "all") {
      params.sub_id = subCode;
    }

    if (DBTypeCode !== "all") {
      params.db_type_code = DBTypeCode;
    }
    if (statuCode !== "") {
      params.desg_stat_code = statuCode;
    }
    if (newModeldataName !== "") {
      params.model_name = newModeldataName;
    }
    projectApi.getModeldataList(params).then((res) => {
      if (res.code === 200) {
        setModelList(res.data.items);
        setTotal(res.data.total);
        setPlainOptionList(res.data.items);
      } else {
        setPlainOptionList([]);
        setModelList([]);
        setTotal(0);
      }
    });
  };

  // 模型数据查询
  useEffect(() => {
    getModeldataList();
  }, [
    layerCode,
    subCode,
    DBTypeCode,
    statuCode,
    newModeldataName,
    orderList,
    pageNumber,
    pageSize,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    // 清除定时器
    return () => clearInterval(timer);
  }, [modelList]);

  // 分页
  const pageChange = (page, size) => {
    setPageNumber(page);
    setPageSize(size);
  };

  // 排序 正序
  const sortOrder = (item, id) => {
    setOrderList([item.key, id]);
    setPageNumber(1);
  };

  // 全选反选
  const onCheckAllChange = (e) => {
    // 对勾选数据进行筛选
    const data = plainOptionList?.filter(
      (item) =>
        item?.desg_stat_code === "IN_DESIGN" ||
        item?.desg_stat_code === "RELEASED" ||
        item?.desg_stat_code === "DISCARDED"
    );
    setCheckedList(e.target.checked ? data : []);
    setIndeterminate(false);
    setChecked(e.target.checked ? true : false);
    setCheckAll(e.target.checked);
  };

  // 反选
  const checkChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptionList.length);
    setCheckAll(list.length === plainOptionList.length);
  };

  // 修改模型
  const modelHandle = (id) => {
    setModifyLoding(true);
    setAddNew(false);
    getModelDesc(id);
    setModifyId(id);
  };

  // 获取修改模型数据
  const getModelDesc = (id) => {
    projectApi.getModelDesc(id).then((res) => {
      if (res.code === 200) {
        setColNameList(
          res?.data?.columns.reduce((pre, info) => [...pre, info?.col_name], [])
        );
        setModelTableList(res.data);
        setDialogDrawerVisible(true);
      } else {
        setModelTableList({});
        setDialogDrawerVisible(true);
      }
    });
  };

  // 模型设计-copy模型数据
  const copyTableDesign = (id) => {
    setModifyLoding(true);
    setCopiedFlag(true);
    setAddNew(true);
    projectApi.copyTableDesign(id).then((res) => {
      if (res.code === 200) {
        setColNameList(
          res?.data?.columns.reduce((pre, info) => [...pre, info?.col_name], [])
        );
        setModelTableList(res.data);
        setDialogDrawerVisible(true);
        message.info("复制成功");
      } else {
        setModelTableList({});
        setDialogDrawerVisible(true);
        message.info("复制失败");
      }
    });
  };

  // 获取模型详情的信息
  const getModelDesignInfo = () => {
    setDetailDrawerLoading(true);
  };

  // 批量废弃模型
  const batchDelete = (value) => {
    MessageModal({
      type: "warning",
      description: "确认要废弃模型吗？",
      message: "审核通过后将从数据存储上删除模型",
      title: "废弃模型",
      okText: "废弃",
      onOk: () => {
        projectApi.abandonModelData(value).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getModeldataList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 删除模型
  const DeleteItem = (id) => {
    MessageModal({
      type: "warning",
      description: "确认要删除模型吗？",
      title: "删除模型",
      okText: "删除",
      onOk: () => {
        projectApi.deleteModelData(id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getModeldataList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 取消废弃模型
  const cancelDelete = (value) => {
    MessageModal({
      description: "确认要取消废弃模型吗？",
      title: "取消废弃模型",
      okText: "取消废弃",
      message: "审核通过后将重新创建模型（数据不会自动恢复）",
      onOk: () => {
        projectApi.abandonModelData(value).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getModeldataList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 还原模型
  const reductionDelete = (id) => {
    MessageModal({
      description: "确认要还原模型吗？",
      title: "还原模型",
      okText: "还原",
      message: "模型将还原为最近发布的版本",
      onOk: () => {
        projectApi.restoreDesign(id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getModeldataList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 新建模型
  const addDesign = (value, ddlTableList) => {
    let ptnData = value?.ptns?.reduce(
      (cur, pre) => [
        ...cur,
        { ptn_name: pre.ptn_name, ptn_value: pre.ptn_value },
      ],
      []
    );
    if (addNew) {
      projectApi
        .addDesign({
          base_info: {
            data_layer_id: value?.data_layer_id,
            sub_id: value?.sub_id,
            table_name: value?.table_name,
            table_cmnt: value?.table_cmnt,
            metadata_desc: value?.metadata_desc,
            db_type_code: value?.db_type_code,
          },
          columns: value?.columns,
          indexes: value?.indexes?.length !== 0 ? value?.indexes : [],
          ptns:
            value?.ptns?.length !== 0
              ? [
                  {
                    ptn_type_code: value?.ptn_type_code,
                    ptn_col_name: value?.ptn_col_name,
                    ptn_cols: ptnData,
                  },
                ]
              : value?.ptn_type_code !== null && value?.ptn_col_name !== null
              ? [
                  {
                    ptn_type_code: value?.ptn_type_code,
                    ptn_col_name: value?.ptn_col_name,
                  },
                ]
              : [],
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            setDialogDrawerVisible(false);
            getModeldataList();
          } else {
            message.error(res.message);
          }
        });
    } else {
      projectApi
        .modifyDesign({
          base_info: {
            data_layer_id: value?.data_layer_id,
            sub_id: value?.sub_id,
            table_name: value?.table_name,
            table_cmnt: value?.table_cmnt,
            metadata_desc: value?.metadata_desc,
            db_type_code: value?.db_type_code,
            table_id: value?.table_id,
          },
          columns: value?.columns,
          indexes: value?.indexes?.length !== 0 ? value?.indexes : [],
          ptns:
            value?.ptns?.length !== 0
              ? [
                  {
                    ptn_type_code: value?.ptn_type_code,
                    ptn_col_name: value?.ptn_col_name,
                    ptn_def_id: ddlTableList?.ptns[0]?.ptn_def_id,
                    ptn_cols: ptnData,
                  },
                ]
              : value?.ptn_type_code !== null && value?.ptn_col_name !== null
              ? [
                  {
                    ptn_type_code: value?.ptn_type_code,
                    ptn_col_name: value?.ptn_col_name,
                    ptn_def_id: ddlTableList?.ptns[0]?.ptn_def_id,
                  },
                ]
              : [],
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            setDialogDrawerVisible(false);
            getModeldataList();
          } else {
            message.error(res.message);
          }
        });
    }
  };

  // 获取元数据详情的信息
  const getmetaDataInfo = (ids) => {
    setDetailDrawerLoading(true);
    setDetailId(ids.metadata_id);

    // 获取标签信息
    projectApi
      .getMetadataTag({ metadata_ver_id: ids?.metadata_ver_id })
      .then((res) => {
        if (res.code === 200) {
          setChooseList(res.data[ids?.metadata_ver_id]);
        } else {
          setChooseList([]);
        }
      });

    projectApi
      .getMetadataTable({ metadata_ver_id: ids?.metadata_ver_id })
      .then((res) => {
        if (res.code === 200) {
          if (res.data.ds_metadata_id !== null) {
            metadataDetail(res.data, ids?.metadata_ver_id);
            setDetailDrawerVisible(true);
          } else {
            message.info("获取元数据详情失败");
            setDetailDrawerLoading(false);
          }
        } else {
          message.info("获取元数据详情失败");
        }
      });
  };
  // 元数据详情信息
  const metadataDetail = (data, id) => {
    projectApi
      .getBloodrRelation({
        metadata_ver_id: id,
      })
      .then((res) => {
        if (res.code === 200) {
          setRelationList(res.data);
        } else {
          setRelationList([]);
        }
      });
    // 表数据详情（修改模型的时候选择）
    projectApi
      .getMetaDBTable(data.metadata_ver_id)
      .then((res) => {
        if (res.code === 200) {
          setModelDesignInfo({ ...data, table_info: res.data });
        } else {
          setModelDesignInfo(data);
        }
      })
      .finally(() => {
        setDetailDrawerLoading(false);
      });
  };

  // 获取版本
  const getMetadataVerson = (id) => {
    projectApi.getMetadataVerson({ metadata_id: id }).then((res) => {
      if (res.code === 200) {
        setMetadataVersonList(res.data);
      } else {
        setMetadataVersonList([]);
      }
    });
  };

  useEffect(() => {
    if (DetailDrawerVisible) getMetadataVerson(DetailId);
  }, [DetailDrawerVisible]);

  // 发布审核
  const releaseDrawerSuccess = (data) => {
    submitModelDesign(data);
  };

  // 发布审核请求
  const submitModelDesign = (data) => {
    projectApi.submitModelDesign(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setReleaseDrawerVisible(false);
        getModeldataList();
      } else {
        message.error(res.message);
      }
    });
  };

  // const handleStateChange = (newState) => {
  //   setFlag({ ...flag, [newState]: true });
  //   console.log(flag, newState);
  // };

  return (
    <div className="ModelDesignContent">
      {DialogDrawerVisible ? (
        <DialogDrawer
          DialogDrawerVisible={DialogDrawerVisible}
          setDialogDrawerVisible={setDialogDrawerVisible}
          addNew={addNew}
          layerList={layerList}
          subId={subId}
          DBType={DBType}
          addDesign={addDesign}
          modelTableList={modelTableList}
          setColNameList={setColNameList}
          colNameList={colNameList}
          modifyId={modifyId}
          modifyLoding={modifyLoding}
          tableSuffixList={tableSuffixList}
          setModifyLoding={setModifyLoding}
          copiedFlag={copiedFlag}
        ></DialogDrawer>
      ) : (
        <>
          {/* 检索区域 */}
          <div className="topBox">
            {topLoading ? (
              <MySpin style={{ height: "100px" }}></MySpin>
            ) : (
              <>
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{ height: "100%" }}
                >
                  <div className="searchItem">
                    <div className="label">数据分层 :</div>
                    <div className="optionsBox">
                      <span
                        style={{
                          backgroundColor:
                            "all" === layerCode ? "#006AB2" : null,
                          color: "all" === layerCode ? "#fff" : null,
                        }}
                        onClick={() => {
                          setLayerCode("all");
                          setSubCode("all");
                          setPageNumber(1);
                        }}
                      >
                        全部
                      </span>
                      {layerList?.map((item, index) => (
                        <div
                          key={index}
                          className="optionItem"
                          onClick={() => {
                            setLayerCode(item.data_layer_id);
                            setSubCode("all");
                            setPageNumber(1);
                          }}
                          style={{
                            backgroundColor:
                              item.data_layer_id === layerCode
                                ? "#006AB2"
                                : null,
                            color:
                              item.data_layer_id === layerCode ? "#fff" : null,
                          }}
                        >
                          {item.data_layer_name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="searchItem">
                    <div className="label">主题域 :</div>
                    <div className="optionsBox">
                      {layerCode !== "all" ? (
                        <>
                          <span
                            style={{
                              backgroundColor:
                                "all" === subCode ? "#006AB2" : null,
                              color: "all" === subCode ? "#fff" : null,
                            }}
                            onClick={() => {
                              setSubCode("all");
                              setPageNumber(1);
                            }}
                          >
                            全部
                          </span>
                          {subList?.map((item, index) => (
                            <div
                              key={index}
                              className="optionItem"
                              onClick={() => {
                                setSubCode(item.sub_id);
                                setPageNumber(1);
                              }}
                              style={{
                                backgroundColor:
                                  item.sub_id === subCode ? "#006AB2" : null,
                                color: item.sub_id === subCode ? "#fff" : null,
                              }}
                            >
                              {item.sub_name}
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="searchItem">
                    <div className="label">存储类型 :</div>
                    <div className="optionsBox">
                      <span
                        style={{
                          backgroundColor:
                            "all" === DBTypeCode ? "#006AB2" : null,
                          color: "all" === DBTypeCode ? "#fff" : null,
                        }}
                        onClick={() => {
                          setDBTypeCode("all");
                          setPageNumber(1);
                        }}
                      >
                        全部
                      </span>
                      {DBType?.map((item, index) => (
                        <div
                          key={index}
                          className="optionItem"
                          onClick={() => {
                            setDBTypeCode(item);
                            setPageNumber(1);
                          }}
                          style={{
                            backgroundColor:
                              item === DBTypeCode ? "#006AB2" : null,
                            color: item === DBTypeCode ? "#fff" : null,
                          }}
                        >
                          {dicCodeInfoList?.DG_DB_TYPE?.map(
                            (value) => value[item]
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="searchItem">
                    <div className="label">状态 :</div>
                    <div className="optionsBox">
                      <span
                        style={{
                          backgroundColor: "" === statuCode ? "#006AB2" : null,
                          color: "" === statuCode ? "#fff" : null,
                        }}
                        onClick={() => {
                          setStatuCode("");
                          setPageNumber(1);
                        }}
                      >
                        全部
                      </span>
                      {statuDataType?.map((item, index) => (
                        <div
                          key={index}
                          className="optionItem"
                          onClick={() => {
                            setStatuCode(item);
                            setPageNumber(1);
                          }}
                          style={{
                            backgroundColor:
                              item === statuCode ? "#006AB2" : null,
                            color: item === statuCode ? "#fff" : null,
                          }}
                        >
                          {dicCodeInfoList?.DG_TABLE_DESG_STAT?.map(
                            (value) => value[item]
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="searchItem">
                    <div className="label">模型名称 :</div>
                    <div className="optionsBox">
                      <Input
                        bordered={false}
                        allowClear
                        style={{
                          borderBottom: "1px solid #999999",
                          width: "280px",
                        }}
                        value={modeldataName}
                        onChange={(e) => {
                          setModeldataName(e.target.value);
                          if (e.target.value === "") {
                            setNewModeldataName("");
                            setPageNumber(1);
                          }
                        }}
                        onPressEnter={(e) => {
                          setNewModeldataName(e.target.value);
                          setPageNumber(1);
                        }}
                      />
                    </div>
                  </div>
                </Scrollbars>
              </>
            )}
          </div>
          {/* 排序 */}
          <div className="filterItem">
            <div className="optionBoxs">
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                style={{ marginTop: "1px" }}
                disabled={
                  statuCode === "IN_APPROVAL" || statuCode === "TO_BE_DEPLOYED"
                }
              >
                全选
              </Checkbox>
              {orderDataList.map((item) => (
                <div key={item.key} className="optionItem">
                  {item.label}
                  <div className="iconBox">
                    <CaretUpOutlined
                      className="upIcon"
                      onClick={() => {
                        sortOrder(item, "asc");
                        setUpOrderCode(item);
                        setDownOrderCode({});
                      }}
                      style={{ color: item === upOrderCode ? "#0068B2" : null }}
                    />
                    <CaretDownOutlined
                      className="downIcon"
                      onClick={() => {
                        sortOrder(item, "desc");
                        setDownOrderCode(item);
                        setUpOrderCode({});
                      }}
                      style={{
                        color: item === downOrderCode ? "#0068B2" : null,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div style={{ color: "#006AB2" }} className="buttonBox">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setDialogDrawerVisible(true);
                  setAddNew(true);
                  setCopiedFlag(false);
                  setModelTableList({});
                }}
              >
                新建
              </Button>

              <Dropdown
                overlay={menu}
                trigger={["click"]}
                disabled={
                  statuCode === "IN_APPROVAL" || statuCode === "TO_BE_DEPLOYED"
                }
              >
                <Button type="primary">
                  批量操作
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>

          {/* 展示 */}
          <div className="cardBox">
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{ height: "100%", width: "100%" }}
            >
              <CheckboxGroup
                onChange={checkChange}
                value={checkedList}
                style={{ width: "100%" }}
              >
                {loading ? (
                  <MySpin></MySpin>
                ) : modelList?.length > 0 ? (
                  modelList?.map((item, index) => (
                    <MetaDesignCard
                      modelInfo={item}
                      key={index}
                      plainOptionList={plainOptionList}
                      cancelDelete={cancelDelete}
                      batchDelete={batchDelete}
                      DeleteItem={DeleteItem}
                      reductionDelete={reductionDelete}
                      modelHandle={modelHandle}
                      setDialogDrawerVisible={setDialogDrawerVisible}
                      setReleaseDrawerVisible={setReleaseDrawerVisible}
                      setModelMetaInfo={setModelMetaInfo}
                      getmetaDataInfo={getmetaDataInfo}
                      setSubmitModelDesignData={setSubmitModelDesignData}
                      getModeldataList={getModeldataList}
                      // setCopiedFlag={setCopiedFlag}
                      copyTableDesign={copyTableDesign}
                    ></MetaDesignCard>
                  ))
                ) : (
                  <div className="noData">
                    <Empty></Empty>
                  </div>
                )}
              </CheckboxGroup>
            </Scrollbars>
          </div>

          {loading ? null : total >= 1 ? (
            <div className="paginationBox">
              <Pagination
                onChange={pageChange}
                className="myPagination"
                pageSize={pageSize}
                current={pageNumber}
                total={total}
                showTotal={(total) => `共 ${total} 条`}
                showSizeChanger={true}
                showQuickJumper
                pageSizeOptions={[10, 20, 50, 100]}
              />
            </div>
          ) : null}
        </>
      )}
      <ReleaseDrawer
        title="模型提交发布"
        onOk={releaseDrawerSuccess}
        visible={ReleaseDrawerVisible}
        onCancel={() => {
          setReleaseDrawerVisible(false);
        }}
        submitModelDesignData={submitModelDesignData}
        // flag={flag}
      ></ReleaseDrawer>
      <DetailDrawer
        visible={DetailDrawerVisible}
        defaultData={ModelDesignInfo}
        onCancel={() => {
          setDetailDrawerVisible(false);
        }}
        metadataVersoList={metadataVersoList}
        relationList={relationList}
        loading={DetailDrawerLoading}
        getModelDesignInfo={getModelDesignInfo}
        chooseList={chooseList}
        // metaDataCode={metaDataCode}
        MetadataStorageList={MetadataStorageList}
        modelMetaInfo={modelMetaInfo}
        getmetaDataInfo={getmetaDataInfo}
      ></DetailDrawer>
    </div>
  );
};
export default ModelDesign;
