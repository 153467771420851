import { Badge, Tooltip, Checkbox } from "antd";
import {
  DeleteOutlined,
  FormOutlined,
  RollbackOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import greyIcon from "../../../../../assets/API灰.svg";
import yellowIcon from "../../../../../assets/API黄.svg";
import blueIcon from "../../../../../assets/API蓝.svg";
import greenIcon from "../../../../../assets/API绿.svg";
const ServiceDevCard = (props) => {
  const {
    batchDelete,
    cancelDelete,
    defaultData,
    modifyDevService, // 编辑服务
    submitCheck, // 提交审核
    setDialogLoading,
    getDetailInfo,
  } = props;

  // img src切换
  const changeImage = (value) => {
    if (value === "TO_BE_RELEASED") {
      return blueIcon;
    } else if (value === "IN_APPROVAL") {
      return yellowIcon;
    } else if (value === "RELEASED") {
      return greenIcon;
    } else if (value === "DISCARDED") {
      return greyIcon;
    }
  };

  const titleTypeBgc = (data) => {
    if (data === "ORIGINAL") {
      return "#61D4AD";
    }
    if (data === "NEW") {
      return "#FEC054";
    }
    if (data === "UPDATE") {
      return "#006AB2";
    }
    if (data === "DISCARD") {
      return "#FE545F";
    }
  };

  // 流程状态颜色切换
  const changeColor = (value) => {
    if (value === "TO_BE_RELEASED") {
      return (
        <>
          <Badge color="#6CAEFF" /> 待发布
        </>
      );
    } else if (value === "IN_APPROVAL") {
      return (
        <>
          <Badge color="#FEC054" />
          审核中
        </>
      );
    } else if (value === "RELEASED") {
      return (
        <>
          <Badge color="#61D4AD" />
          已发布
        </>
      );
    } else if (value === "DISCARDED") {
      return (
        <>
          <Badge color="#D8D8D8" />
          已废弃
        </>
      );
    }
  };

  return (
    <div className="cardItem">
      <div className="leftItem">
        <Checkbox
          value={defaultData}
          disabled={
            !(
              defaultData?.rls_stat_code === "TO_BE_RELEASED" ||
              defaultData?.rls_stat_code === "DISCARDED"
            )
          }
        ></Checkbox>
      </div>
      <div className="imgItem">
        <img
          src={changeImage(defaultData?.rls_stat_code)}
          className="infoImg"
          style={{ width: "56px", height: "56px" }}
        ></img>
      </div>

      <div className="rightItem">
        <div
          className="title"
          onClick={() => {
            getDetailInfo(defaultData?.last_ver_no, defaultData, true);
          }}
        >
          <span>{`${defaultData?.chi_name} / ${defaultData?.eng_name}`}</span>
          <span
            className="titleType"
            style={{ background: titleTypeBgc(defaultData?.chg_type_code) }}
          >
            {defaultData?.chg_type_code_value}
          </span>
        </div>
        <div className="webContent">
          {changeColor(defaultData?.rls_stat_code)}
          <span className="web">{defaultData?.api_url}</span>
        </div>
        <div className="detailContent">
          <span className="label">当前版本</span>
          <span className="value">{defaultData?.last_ver_no}</span>
          <span className="label">创建人</span>
          <span className="value">{defaultData?.created_by_id_value}</span>
          <span className="label">创建时间</span>
          <span className="value">{defaultData?.created_time}</span>
          <span className="label">更新时间</span>
          <span className="value">{defaultData?.updated_time}</span>
        </div>
      </div>
      <div className="iconItem">
        {(defaultData?.chg_type_code !== "DISCARD" &&
          defaultData?.rls_stat_code === "TO_BE_RELEASED") ||
        defaultData?.rls_stat_code === "RELEASED" ? (
          <Tooltip placement="top" title="编辑" arrowPointAtCenter>
            <FormOutlined
              onClick={() => {
                setDialogLoading(true);
                modifyDevService(defaultData);
              }}
            />
          </Tooltip>
        ) : null}

        {(defaultData?.chg_type_code === "UPDATE" &&
          defaultData?.rls_stat_code === "TO_BE_RELEASED" &&
          defaultData?.last_ver_no) ||
        defaultData?.rls_stat_code === "RELEASED" ? (
          <Tooltip placement="top" title="废弃" arrowPointAtCenter>
            <DeleteOutlined
              style={{ marginLeft: "5px" }}
              onClick={() => batchDelete(defaultData.api_id)}
            />
          </Tooltip>
        ) : null}

        {/* {(defaultData?.chg_type_code === "DISCARD" &&
          defaultData?.rls_stat_code === "TO_BE_RELEASED") ||
        defaultData?.rls_stat_code === "DISCARDED" ? (
          <Tooltip placement="top" title="取消废弃" arrowPointAtCenter>
            <RollbackOutlined
              onClick={() => cancelDelete(defaultData.api_id)}
              style={{ marginLeft: "5px" }}
            />
          </Tooltip>
        ) : null} */}

        {defaultData?.rls_stat_code === "TO_BE_RELEASED" ||
        defaultData?.rls_stat_code === "DISCARDED" ? (
          <Tooltip placement="top" title="提交发布" arrowPointAtCenter>
            <SnippetsOutlined
              style={{ marginLeft: "5px" }}
              onClick={() => {
                setDialogLoading(true);
                submitCheck([defaultData]);
              }}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
export default ServiceDevCard;
