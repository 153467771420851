import { useEffect, useState } from "react";
import HeaderTitle from "../../../components/HeaderTitle";
import { Table } from "antd";
import MyEmpty from "../../../components/Empty";
import ReactJson from "react-json-view";
import projectApi from "../../../apis/project";
import { useNavigate } from "react-router-dom";

const ApiContent = (props) => {
  const { id } = props;
  const Navigate = useNavigate();
  const [requestData, setRequestData] = useState([]); // 请求头参数
  const [requestBodyData, setRequestBodyData] = useState([]); // 请求体参数
  const [respondData, setRespondData] = useState([]); // 返回参数
  const [requestBodyExample, setRequestBodyExample] = useState({}); // 请求体示例
  const [requestExample, setRequestExample] = useState({}); // 请求头示例
  const [respondExample, setRespondExample] = useState({}); // 响应示例

  const [errorData, setErrorData] = useState([]); // 错误码

  const [defaultData, setdefaultData] = useState({}); // json数据
  const [apiInfo, setApiInfo] = useState({});

  // 获取获取单个api详情
  const getApiDetail = () => {
    projectApi.getApiDetail(id).then((res) => {
      if (res.code === 200) {
        // console.log(JSON.parse(res.data.req_parm_exp), res.data.req_parm_exp);

        setdefaultData(JSON.parse(res.data.req_parm_exp));
        setApiInfo(res.data);
      } else {
        setApiInfo({});
        setdefaultData({});
      }
    });
  };

  useEffect(() => {
    getApiDetail();
    setErrorData([
      {
        key: 1,
        name: "-2",
        example: "请求过快",
      },
      {
        key: 2,
        name: "-1",
        example: "执行出现异常",
      },
      {
        key: 3,
        name: "0",
        example: "参数验证未通过",
      },

      {
        key: 4,
        name: "1",
        example: "执行成功",
      },
      {
        key: 5,
        name: "2",
        example: "执行中",
      },
    ]);
  }, []);

  // 处理请求体参数
  // const dealwithReqBody = (data) => {
  //   data = data?.reduce((cur, pre) => {
  //     if (pre.children.length !== 0) {
  //       dealwithReqBody(pre.children);
  //     } else {
  //       return [
  //         ...cur,
  //         {
  //           name: pre.name,
  //           "x-example": pre.value,
  //           type: pre.dataType,
  //           description: pre.description,
  //           required: pre.required,
  //         },
  //       ];
  //     }
  //   }, []);
  //   return data;
  // };

  // // 处理响应头参数
  // const dealwithRepParameter = (data) => {
  //   data = data?.reduce((cur, pre) => {
  //     if (pre.children.length !== 0) {
  //       dealwithRepParameter(pre.children);
  //     } else {
  //       return [
  //         ...cur,
  //         {
  //           name: pre.name,
  //           "x-example": pre.value,
  //           type: pre.dataType,
  //           description: pre.description,
  //         },
  //       ];
  //     }
  //   }, []);
  //   return data;
  // };

  // 处理响应示例数据
  const dealwithData = (data) => {
    let object = {};
    data.map((pre) => {
      if (pre.children.length !== 0) {
        dealwithData(pre.children);
      } else {
        return (object[pre.name] = pre.value);
      }
      return object;
    });
    return object;
  };

  // 处理请求体参数
  const extractChildren = (data) => {
    if (data.dataType === "Object" || data.dataType === "Array") {
      if (Array.isArray(data.children)) {
        const children = data.children
          .map((child) => extractChildren(child))
          .filter((child) => child !== null);
        return { ...data, children };
      } else {
        return null;
      }
    } else {
      return data;
    }
  };
  const transformData = (data) => {
    return data?.map((obj, index) => {
      if (obj.children.length === 0) {
        const { children, ...rest } = obj;

        return { ...rest, only: index };
      } else {
        return { ...obj, only: index };
      }
    });
  };
  const flattenArray = (arr) => {
    return arr.reduce((flat, item) => {
      return flat.concat(Array.isArray(item) ? flattenArray(item) : item);
    }, []);
  };

  const cleanStructure = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(cleanStructure);
    }

    if (obj && typeof obj === "object") {
      const newObj = Array.isArray(obj) ? [] : {};

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key] = cleanStructure(obj[key]);
        }
      }
      // const type = ["Integer", "String", "Boolean", "Double"];
      const type = ["Object", "Array"];
      if (!type?.includes(newObj.dataType)) {
        delete newObj.children;
      } else if (newObj.children) {
        newObj.children = cleanStructure(newObj.children);
      }

      return newObj;
    }

    // 如果是其他类型，直接返回
    return obj;
  };

  useEffect(() => {
    if (Object.keys(defaultData)?.length !== 0) {
      // 请求头参数
      if (defaultData?.parameters?.length !== 0) {
        setRequestData(defaultData.parameters);
      }
      // 请求体参数
      if (defaultData?.requestBody !== "") {
        if (
          defaultData?.requestBodyDefinition !== null &&
          defaultData?.requestBodyDefinition !== undefined
        ) {
          // console.log(2222, defaultData?.requestBodyDefinition);

          const data = [defaultData?.requestBodyDefinition]
            .map((data) => extractChildren(data))
            .filter((data) => data !== null);
          const cleanedData = cleanStructure(data);
          setRequestBodyData(cleanedData);
        }
      }

      const requestExample = {};
      defaultData.parameters.map(
        (item) => (requestExample[item.name] = item.value)
      );

      // 响应参数
      // console.log(defaultData?.responseBodyDefinition?.children);

      if (defaultData?.responseBodyDefinition === undefined) {
        setRespondData([]);
      } else {
        // console.log(111111, defaultData?.responseBodyDefinition?.children);
        const respond = defaultData?.responseBodyDefinition?.children?.find(
          (item) => item.dataType === "Object" || item.dataType === "Array"
        );
        const string = defaultData?.responseBodyDefinition?.children?.find(
          (item) =>
            item.name === "result" &&
            (item.dataType === "Integer" || item.dataType === "String")
        );

        if (string) {
          const data = string;
          delete data.children;
          setRespondData([data]);
        } else {
          const findNonStringDataType = () => {
            return defaultData?.responseBodyDefinition?.children?.find(
              (child) => child.dataType !== "String"
            );
          };

          const nonStringItem = findNonStringDataType();
          if (nonStringItem) {
            let respondData = null;

            if (respond?.dataType === "Object") {
              const integerChild = respond?.children?.filter(
                (child) =>
                  child?.dataType === "Array" || child?.dataType === "Object"
              );

              const otherChildren = respond?.children?.filter(
                (child) => child?.dataType !== "Array"
              );
              // console.log(33333, integerChild);

              let combinedArrayChildren = integerChild?.flatMap(
                (child) =>
                  child.children[0]?.children?.length > 0
                    ? child.children[0].children
                    : [child]
                // child.children.length > 0 ? child.children : [child]
              );

              let flattenedCombinedArrayChildren = flattenArray(
                combinedArrayChildren
              );
              // console.log(22222, flattenedCombinedArrayChildren, otherChildren);
              if (integerChild?.length !== 0) {
                respondData = [
                  ...otherChildren,
                  ...flattenedCombinedArrayChildren,
                  // ...(integerChild.children[0]?.children?.length > 0
                  //   ? integerChild.children[0].children
                  //   : [integerChild]),
                ];
              } else {
                respondData = respond?.children;
              }

              // const uniqueData = Array.from(
              //   new Map(respondData.map((item) => [item.name, item])).values()
              // );
              const uniqueData = respondData.reduce((acc, item) => {
                const existingItem = acc.find((e) => e.name === item.name);

                if (!existingItem) {
                  acc.push(item);
                } else {
                  if (item.description && !existingItem.description) {
                    Object.assign(existingItem, item);
                  }
                }

                return acc;
              }, []);
              // console.log(uniqueData);

              const news = transformData(uniqueData);
              const cleanedData = cleanStructure(news);
              setRespondData(cleanedData);
            } else {
              const news = transformData(respond?.children[0].children);
              // setRespondData(news);
              const cleanedData = cleanStructure(news);
              setRespondData(cleanedData);
            }
          } else {
            const stringData = defaultData?.responseBodyDefinition?.children[2];
            const news = transformData([stringData]);
            // setRespondData(news);
            const cleanedData = cleanStructure(news);
            setRespondData(cleanedData);
          }
        }
      }

      if (
        defaultData?.responseBody !== undefined &&
        defaultData?.responseBody !== ""
      ) {
        // 返回数据例子
        setRespondExample(JSON.parse(defaultData?.responseBody));
      }
      if (defaultData?.requestBody !== "") {
        // 请求体例子
        setRequestBodyExample(JSON.parse(defaultData?.requestBody));
      }
      // // 返回数据例子
      // setRespondExample(JSON.parse(defaultData?.responseBody));
    }
  }, [defaultData]);

  const requestColumns = [
    {
      title: "参数名",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "示例值",
      dataIndex: "value",
      width: "15%",
    },
    {
      title: "参数类型",
      dataIndex: "dataType",
      width: "8%",
    },
    {
      title: "是否必填",
      dataIndex: "required",
      render: (_, info) => (info?.required ? "是" : "否"),
      width: "8%",
    },
    {
      title: "参数描述",
      dataIndex: "description",
    },
  ];

  const requestBodyColumns = [
    {
      title: "参数名",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "示例值",
      dataIndex: "value",
      width: "15%",
    },
    {
      title: "参数类型",
      dataIndex: "dataType",
      width: "8%",
    },
    {
      title: "是否必填",
      dataIndex: "required",
      render: (_, info) => (info?.required ? "是" : "否"),
      width: "8%",
    },
    {
      title: "参数描述",
      dataIndex: "description",
    },
  ];

  const respondColumns = [
    {
      title: "参数名",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "示例值",
      dataIndex: "value",
      width: "30%",
      className: "wrap-column",
      render: (_, info) => {
        if (info?.value.includes('\\"')) {
          const unescapedData = info?.value.replace(/\\\"/g, '"');
          const convertedData = JSON.parse(unescapedData);
          return JSON.stringify(convertedData);
        } else {
          return info?.value;
        }
      },
    },
    {
      title: "参数类型",
      dataIndex: "dataType",
      width: "10%",
    },
    {
      title: "参数描述",
      dataIndex: "description",
      width: "15%",
    },
  ];

  // 错误码
  const errorColumns = [
    {
      title: "代码",
      dataIndex: "name",
    },
    {
      title: "说明",
      dataIndex: "example",
    },
  ];

  const handleClick = () => {
    // 获取当前 URL 的查询参数
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(new URL(currentUrl).search);
    const showName = urlParams.get("show_name");

    if (showName) {
      window.open("/overview", "_blank");
    } else {
      Navigate("/overview", { replace: true });
    }
  };

  return (
    <div className="apiReference">
      <div className="contentValue">
        <div className="BasicInfo">
          <HeaderTitle title="基本信息"></HeaderTitle>
          <ul className="basicItem">
            <li>
              <div></div>
              <span className="child">接口id：</span>
              <span> {apiInfo?.api_id}</span>
            </li>
            <li>
              <div></div>
              <span className="child">接口Url：</span>
              <span>
                {`https://api.touzhiwang.com/data_service${apiInfo?.api_url}`}
              </span>
            </li>
            <li>
              <div></div>
              <span className="child">请求方式：</span>
              <span> {apiInfo?.req_method_code}</span>
            </li>
            <li>
              <div></div>
              <span className="child"> Content-Type：</span>
              <span> {apiInfo?.content_type}</span>
            </li>
            <li>
              <div></div>
              <span className="child">接口描述：</span>
              <span style={{ whiteSpace: "pre-line" }}>
                {defaultData.description}
              </span>
              {/* {defaultData?.description} */}
            </li>
          </ul>
        </div>
        <div className="requestParam">
          <HeaderTitle title="请求参数"></HeaderTitle>
          {/* 请求头固定部分 */}
          <div className="headers">
            <div>
              <div>
                需要添加请求header
                <span
                  onClick={handleClick}
                  style={{
                    color: "#1890ff",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  没有申请请跳转
                  <span style={{ textDecoration: "underline" }}>申请服务</span>
                </span>
              </div>
              <div> 例: Authorization: Bearer ***</div>
            </div>
          </div>
          {defaultData?.parameters?.length !== 0 ? (
            <>
              <div className="requestName">params</div>
              <Table
                className="myFormTable"
                columns={requestColumns}
                dataSource={requestData}
                bordered
                pagination={false}
                locale={{
                  emptyText: <MyEmpty></MyEmpty>,
                }}
                rowKey={(record, id) => id}
              />
            </>
          ) : null}
          {defaultData?.requestBody !== "" ? (
            <>
              <div className="requestName" style={{ marginTop: "20px" }}>
                requestBody
              </div>
              <Table
                className="myFormTable"
                columns={requestBodyColumns}
                dataSource={requestBodyData}
                bordered
                pagination={false}
                locale={{
                  emptyText: <MyEmpty></MyEmpty>,
                }}
                // rowKey={(record, id) => Math.random()}
              />
            </>
          ) : null}
        </div>
        <div className="respondParam">
          <HeaderTitle title="返回参数"></HeaderTitle>
          <Table
            className="myFormTable"
            columns={respondColumns}
            dataSource={respondData}
            bordered
            pagination={false}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
            scroll={{ x: 500 }}
            rowKey={(record) => record.only}
          />
        </div>
        <div className="exampleInfo">
          <HeaderTitle title="示例"></HeaderTitle>
          {/* <div className="exampleItem">
            <div className="example">请求示例</div>

            <div className="value">
              <ReactJson
                src={requestExample}
                name={null}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              ></ReactJson>
            </div>
          </div> */}

          {/* requestBodyData?.length !== 0 */}
          {Object.keys(requestBodyExample)?.length !== 0 ? (
            <>
              <div className="exampleItem">
                <div className="example">请求体示例</div>
                <div className="value">
                  <ReactJson
                    style={{
                      fontFsmily: "sans-serif",
                    }}
                    name={null}
                    enableClipboard={true} // 需要复制功能吗
                    displayDataTypes={false}
                    displayObjectSize={false}
                    displayArrayKey={false}
                    displayArrayIndex={false}
                    src={requestBodyExample}
                    // quotesOnKeys={false}//是否显示键的引号
                    // shouldCollapse={({ name }) => name === "list"}
                  ></ReactJson>
                </div>
              </div>
            </>
          ) : null}
          <div className="exampleItem">
            <div className="example">返回示例</div>
            <div className="value">
              <ReactJson
                style={{
                  fontFsmily: "sans-serif",
                }}
                name={null}
                enableClipboard={true} // 需要复制功能吗
                displayDataTypes={false}
                displayObjectSize={false}
                displayArrayKey={false}
                displayArrayIndex={false}
                src={respondExample}
                // quotesOnKeys={false}//是否显示键的引号
                // shouldCollapse={({ name }) => name === "list"}
                // sortKeys={false} //键的排序
                groupArraysAfterLength={20}
              ></ReactJson>
            </div>
          </div>
        </div>
        <div className="errorCodeInfo">
          <HeaderTitle title="错误码"></HeaderTitle>
          <div className="errorValue">
            <Table
              className="myFormTable"
              columns={errorColumns}
              dataSource={errorData}
              bordered
              pagination={false}
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
              rowKey={(record) => Math.random()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApiContent;
