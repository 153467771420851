import "./index.scss";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Drawer, Button, Table, Pagination, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import HeaderTitle from "../../../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import projectApi from "../../../../../apis/project";
import MySpin from "../../../../../components/MySpin";
import MyEmpty from "../../../../../components/Empty";
import LoadingSvg from "../../../../../assets/loading.svg";
const DetailDrawer = (props) => {
  const {
    /* 关闭触发的回调 */
    onCancel,
    visible,
    /* 初始值 */
    defaultData,
    loading,
    agreeItem,
    rejectItem,
    DetailTable,
    changePagination,
    wfTypeCode,
    versonStatusList,
  } = props;

  const [childLoading, setChildLoading] = useState(true);

  const expandedRowRender = (item) => {
    let columns = [
      {
        title: "主题域",
        dataIndex: "sub_name",
        width: "20.5%",
      },
      {
        title: "数据分层",
        dataIndex: "data_layer_name",
        width: "21%",
      },
      {
        title: "英文缩写",
        dataIndex: "sub_eng_abbr",
        width: "21%",
      },
      {
        title: "描述",
        dataIndex: "sub_desc",
        width: "31.5%",
      },
      {
        title: "变更类型",
        dataIndex: "chg_type_code_value",
        width: "7%",
        render: (_, info) => (
          <div
            style={{
              color: changeColor(info?.chg_type_code),
            }}
          >
            {info?.chg_type_code_value}
          </div>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={childList[item.sub_id]}
        pagination={false}
        showHeader={false}
        rowKey={(record, id) => id}
        locale={{
          emptyText: <MyEmpty></MyEmpty>,
        }}
        scroll={{
          x: "100%",
        }}
        loading={
          childLoading
            ? {
                indicator: (
                  <div style={{ background: "#fff" }}>
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  </div>
                ),
              }
            : false
        }
        style={{ borderRadius: "0px" }}
      />
    );
  };

  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [childList, setChidlist] = useState({}); // 子主题域对应表单数据项

  const handleCancel = () => {
    onCancel && onCancel();
  };

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  const changeColor = (data) => {
    if (data === "ORIGINAL") {
      return "#61D4AD";
    }
    if (data === "DISCARD") {
      return "#FE545F";
    } else {
      return "#006AB2";
    }
  };

  // 获取子主题域
  const getSubStandardList = (expanded, record) => {
    if (expanded) {
      setChildLoading(true);
      getChild(record);
    }
    if (expanded === false) {
      setChidlist((item) => {
        let newObj = {};
        for (let key in item) {
          if (key !== record.sub_id) {
            newObj[key] = item[key];
          }
        }
        return newObj;
      });
    }
  };

  // 获取子主题域请求
  const getChild = (record, flag) => {
    projectApi
      .getSubStandardList(record.sub_id, {
        data_layer_name: record?.data_layer_name,
        publish_type: "UN_PUBLISHED",
        page: 1,
        size: 100,
      })
      .then((res) => {
        if (res.code === 200) {
          setChidlist({ ...childList, [record.sub_id]: res.data.items });
          setChildLoading(false);
        } else {
          setChidlist([]);
          message.error(res.message);
          setChildLoading(false);
        }
      });
  };

  useEffect(() => {
    if (wfTypeCode === "DATA_LAYER_RLS") {
      setColumns([
        {
          title: "分层名称",
          dataIndex: "data_layer_name",
        },
        {
          title: "英文名称",
          dataIndex: "data_layer_eng_name",
          width: "15%",
        },
        {
          title: "英文缩写",
          dataIndex: "data_layer_eng_abbr",
        },
        {
          title: "描述",
          dataIndex: "data_layer_desc",
          width: "60%",
        },
        {
          title: "变更类型",
          dataIndex: "chg_type_code_value",
          width: "6%",
          render: (_, info) => (
            <div
              style={{
                color: changeColor(info?.chg_type_code),
              }}
            >
              {info?.chg_type_code_value}
            </div>
          ),
        },
      ]);
    } else if (wfTypeCode === "DATA_ELE_RLS") {
      setColumns([
        {
          title: "中文名",
          dataIndex: "col_name",
        },
        {
          title: "字段名",
          dataIndex: "col_cmnt",
        },
        {
          title: "字段类型",
          dataIndex: "col_data_type_code_value",
        },
        {
          title: "长度",
          dataIndex: "col_length",
        },
        {
          title: "小数位",
          dataIndex: "col_scale",
        },
        {
          title: "描述",
          dataIndex: "data_ele_desc",
          width: "20%",
        },
        {
          title: "变更类型",
          dataIndex: "chg_type_code_value",
          width: "6%",
          render: (_, info) => (
            <div
              style={{
                color: changeColor(info?.chg_type_code),
              }}
            >
              {info?.chg_type_code_value}
            </div>
          ),
        },
      ]);
    } else if (wfTypeCode === "WORD_ROOT_RLS") {
      setColumns([
        {
          title: "英文全称",
          dataIndex: "word_root_eng",
        },
        {
          title: "英文缩写",
          dataIndex: "word_root_eng_abbr",
        },
        {
          title: "中文",
          dataIndex: "word_root_chi",
        },
        {
          title: "描述",
          dataIndex: "word_root_desc",
          width: "30%",
        },
        {
          title: "变更类型",
          dataIndex: "chg_type_code_value",
          width: "6%",
          render: (_, info) => (
            <div
              style={{
                color: changeColor(info?.chg_type_code),
              }}
            >
              {info?.chg_type_code_value}
            </div>
          ),
        },
      ]);
    } else if (wfTypeCode === "SUB_RLS") {
      setColumns([
        {
          title: "主题域",
          dataIndex: "sub_name",
        },
        {
          title: "数据分层",
          dataIndex: "data_layer_name",
        },
        {
          title: "英文缩写",
          dataIndex: "sub_eng_abbr",
        },
        {
          title: "描述",
          dataIndex: "sub_desc",
          width: "30%",
        },
        {
          title: "变更类型",
          dataIndex: "chg_type_code_value",
          width: "6%",
          render: (_, info) => (
            <div
              style={{
                color: changeColor(info?.chg_type_code),
              }}
            >
              {info?.chg_type_code_value}
            </div>
          ),
        },
      ]);
    }
  }, [wfTypeCode]);

  return (
    <Drawer
      title="查看元数据"
      placement="right"
      width="1320px"
      className="dataStandDetailDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              agreeItem(defaultData);
              handleCancel();
            }}
            style={{
              background: "#61D4AD",
              borderColor: "#61D4AD",
              color: "#fff",
            }}
          >
            同意
          </Button>
          <Button
            onClick={() => {
              rejectItem(defaultData);
              handleCancel();
            }}
            style={{
              background: "#FE545F",
              borderColor: "#FE545F",
              color: "#fff",
            }}
          >
            驳回
          </Button>
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <div className="detailsContent">
          {loading ? (
            <MySpin></MySpin>
          ) : (
            <>
              <HeaderTitle title="基本信息"></HeaderTitle>
              <div className="detailsBox">
                <div className="detailItem">
                  <div className="detailKey">标准类型</div>
                  <div className="detailValue" style={{ color: "#006AB2" }}>
                    {defaultData?.wf_type_code_value}
                  </div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">当前版本</div>
                  <div className="detailValue">{defaultData?.cur_ver_no}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">申请发布版本 </div>
                  <div className="detailValue" style={{ color: "#006AB2" }}>
                    {defaultData?.rls_ver_no}
                  </div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">申请人 </div>
                  <div className="detailValue">
                    {defaultData?.apply_by_id_value}
                  </div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">申请时间 </div>
                  <div className="detailValue">{defaultData?.apply_time}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">版本说明 </div>
                  <div className="detailValue">{defaultData?.rn}</div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">发布内容 </div>
                  <div className="detailValue" style={{ width: "600px" }}>
                    本次发布标准
                    <span style={{ color: "#006AB2" }}>
                      {~~versonStatusList?.ORIGINAL +
                        ~~versonStatusList?.NEW +
                        ~~versonStatusList?.UPDATE +
                        ~~versonStatusList?.DISCARD}
                    </span>
                    项 (其中：原有
                    <span>
                      {versonStatusList?.ORIGINAL
                        ? versonStatusList?.ORIGINAL
                        : 0}
                    </span>
                    项， 新增
                    <span>
                      {versonStatusList?.NEW ? versonStatusList?.NEW : 0}
                    </span>
                    项， 修改
                    <span>
                      {versonStatusList?.UPDATE ? versonStatusList?.UPDATE : 0}
                    </span>
                    项， 废弃
                    <span>
                      {versonStatusList?.DISCARD
                        ? versonStatusList?.DISCARD
                        : 0}
                    </span>
                    项)
                  </div>
                </div>
              </div>

              <HeaderTitle title="详情"></HeaderTitle>
              <div className="dataStandardDetailTable">
                <Table
                  expandable={
                    wfTypeCode === "SUB_RLS"
                      ? {
                          expandedRowRender,
                          onExpand: getSubStandardList,
                        }
                      : null
                  }
                  className="myFormTable"
                  columns={columns}
                  dataSource={DetailTable?.items}
                  rowKey={(record, id) => id}
                  locale={{
                    emptyText: <MyEmpty></MyEmpty>,
                  }}
                  pagination={false}
                  scroll={{
                    y: "600px",
                    x: "100%",
                  }}
                />
                {DetailTable?.total >= 1 ? (
                  <div className="paginationBox">
                    <Pagination
                      className="myPagination"
                      showQuickJumper
                      current={pageNumber}
                      total={DetailTable?.total}
                      pageSize={pageSize}
                      pageSizeOptions={[10, 20, 50, 100]}
                      onChange={onChange}
                      showTotal={(total) => `共 ${total} 条`}
                      showSizeChanger={true}
                    />
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      </Scrollbars>
    </Drawer>
  );
};

export default DetailDrawer;
