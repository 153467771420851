import "./index.scss";
import { Tabs, message, Select, Input } from "antd";
import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { useGetState } from "ahooks";
import { useSelector } from "react-redux";
import projectApi from "../../../apis/project";
import Scrollbars from "react-custom-scrollbars";
import DropdownSvg from "../../../assets/下拉.svg";
import ServiceCheckTable from "./components/ServiceCheckTable";
import MessageModal from "../../../components/MessageModal";

const { TabPane } = Tabs;
const { Option } = Select;

const DataServiceCheck = (props) => {
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  // 变更类型
  const changeType = dicCodeInfoList?.DG_CHG_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  // 流程状态
  const statueType = dicCodeInfoList?.DG_WF_APPR_STAT?.map((item) =>
    Object.keys(item)
  ).flat();

  // 我的申请
  const MyapplyState = () => {
    return statueType?.filter(
      (item) => item !== "DEPLOYED" && item !== "TERMINATED"
    );
  };

  // 已审核
  const checkApprState = () => {
    return statueType?.filter(
      (item) =>
        item !== "IN_APPROVAL" && item !== "DEPLOYED" && item !== "TERMINATED"
    );
  };

  // const [orders, setOrders, getOrders] = useGetState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [apiTableLoading, setApiTableLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("1");

  const [checkTypeCode, setCkeckTypeCode] = useState("NEW"); // 已审核变更类型
  const [apicheckTypeCode, setApiCkeckTypeCode] = useState("NEW"); // api已审核变更类型

  const [statueTypeCode, setStatueTypeCode] = useState("IN_APPROVAL"); // 我的申请流程状态
  const [checkStatueTypeCode, setCheckStatueTypeCode] = useState("APPROVED"); // 已审核流程状态

  const [apiStatueTypeCode, setApiStatueTypeCode] = useState("IN_APPROVAL"); // api我的申请流程状态
  const [apiCheckStatueTypeCode, setApiCheckStatueTypeCode] =
    useState("APPROVED"); // api已审核流程状态

  const [inputValue, setInputValue] = useState("");
  const [apiInputValue, setApiInputValue] = useState("");

  const [params, setParams] = useState({ page: 1, size: 10 });
  const [myapplyList, setMyapplyList] = useState({}); // 我的申请
  const [toCheckList, setToCheckList] = useState({}); // 待审核
  const [checkList, setCheckList] = useState({}); // 已审核

  const [apiMyapplyList, setApiMyapplyList] = useState({}); // api我的申请
  const [apiToCheckList, setApiToCheckList] = useState({}); // api待审核
  const [apiCheckList, setApiCheckList] = useState({}); // api已审核

  const [portKey, setPortKey] = useState(""); // 判断排序和分页点击的是哪个表格
  // tab切换
  const tabChange = (key) => {
    setActiveKey(key);
    setInputValue("");
    setApiInputValue("");
  };

  // 获取api服务包我的申请
  const getPkgAuditList = (info) => {
    let paramer = {
      ...params,
    };
    if (info !== undefined) {
      paramer = {
        ...params,
        ...info,
      };
    }
    projectApi.getPkgAuditList(paramer).then((res) => {
      if (res.code === 200) {
        if (activeKey === "1") {
          setApiMyapplyList(res.data);
        } else if (activeKey === "2") {
          setApiToCheckList(res.data);
        } else if (activeKey === "3") {
          setApiCheckList(res.data);
        }
        setApiTableLoading(false);
      } else {
        if (activeKey === "1") {
          setApiMyapplyList({});
        } else if (activeKey === "2") {
          setApiToCheckList({});
        } else if (activeKey === "3") {
          setApiCheckList({});
        }
        setApiTableLoading(false);
      }
    });
  };

  // 获取服务开发我的申请
  const getApiAuditList = (info) => {
    let paramer = {
      ...params,
    };
    if (info !== undefined) {
      paramer = {
        ...params,
        ...info,
      };
    }
    projectApi.getApiAuditList(paramer).then((res) => {
      if (res.code === 200) {
        if (activeKey === "1") {
          setMyapplyList(res.data);
        } else if (activeKey === "2") {
          setToCheckList(res.data);
        } else if (activeKey === "3") {
          setCheckList(res.data);
        }
        setTableLoading(false);
      } else {
        if (activeKey === "1") {
          setMyapplyList({});
        } else if (activeKey === "2") {
          setToCheckList({});
        } else if (activeKey === "3") {
          setCheckList({});
        }
        setTableLoading(false);
      }
    });
  };

  useEffect(() => {
    if (activeKey === "1") {
      setTableLoading(true);
      setApiTableLoading(true);
      getApiAuditList({
        appr_stat_code: statueTypeCode,
        order_list: ["updated_time", "desc"].toString(),
      });
      getPkgAuditList({
        appr_stat_code: apiStatueTypeCode,
        order_list: ["updated_time", "desc"].toString(),
      });
    } else if (activeKey === "2") {
      setTableLoading(true);
      setApiTableLoading(true);
      getApiAuditList({
        appr_stat_code: "IN_APPROVAL",
        order_list: ["apply_time", "desc"].toString(),
      });
      getPkgAuditList({
        appr_stat_code: "IN_APPROVAL",
        order_list: ["apply_time", "desc"].toString(),
      });
    } else if (activeKey === "3") {
      setTableLoading(true);
      setApiTableLoading(true);
      getApiAuditList({
        chg_type_code: checkTypeCode,
        appr_stat_code: checkStatueTypeCode,
        order_list: ["updated_time", "desc"].toString(),
      });
      getPkgAuditList({
        chg_type_code: apicheckTypeCode,
        appr_stat_code: apiCheckStatueTypeCode,
        order_list: ["updated_time", "desc"].toString(),
      });
    }
  }, [activeKey]);

  useEffect(() => {
    if (activeKey === "1" && portKey !== "") {
      portKey === "api" ? setTableLoading(true) : setApiTableLoading(true);
      portKey === "api"
        ? getApiAuditList({
            appr_stat_code: statueTypeCode,
            order_list: ["updated_time", "desc"].toString(),
          })
        : getPkgAuditList({
            appr_stat_code: apiStatueTypeCode,
            order_list: ["updated_time", "desc"].toString(),
          });
    } else if (activeKey === "2" && portKey !== "") {
      portKey === "api" ? setTableLoading(true) : setApiTableLoading(true);
      portKey === "api"
        ? getApiAuditList({
            appr_stat_code: "IN_APPROVAL",
            order_list: ["apply_time", "desc"].toString(),
          })
        : getPkgAuditList({
            appr_stat_code: "IN_APPROVAL",
            order_list: ["apply_time", "desc"].toString(),
          });
    } else if (activeKey === "3" && portKey !== "") {
      portKey === "api" ? setTableLoading(true) : setApiTableLoading(true);
      portKey === "api"
        ? getApiAuditList({
            chg_type_code: checkTypeCode,
            appr_stat_code: checkStatueTypeCode,
            order_list: ["updated_time", "desc"].toString(),
          })
        : getPkgAuditList({
            chg_type_code: apicheckTypeCode,
            appr_stat_code: apiCheckStatueTypeCode,
            order_list: ["updated_time", "desc"].toString(),
          });
    }
  }, [params]);

  // 改变分页数据
  const changePagination = (page, pageSize, port) => {
    setParams({ page: page, size: pageSize });
    setPortKey(port);
  };

  // 排序
  const changeOrders = (data, portKey) => {
    const str = "_value";
    const order = [
      data.field.includes("_value")
        ? data.field.split(str).join("")
        : data.field,
      data.order === "descend" ? "desc" : "asc",
    ]?.toString();
    if (activeKey === "1") {
      portKey === "api"
        ? getApiAuditList({
            appr_stat_code: statueTypeCode,
            order_list: order,
          })
        : getPkgAuditList({
            appr_stat_code: apiStatueTypeCode,
            order_list: order,
          });
    } else if (activeKey === "2") {
      portKey === "api"
        ? getApiAuditList({
            appr_stat_code: "IN_APPROVAL",
            order_list: order,
          })
        : getPkgAuditList({
            appr_stat_code: "IN_APPROVAL",
            order_list: order,
          });
    } else if (activeKey === "3") {
      portKey === "api"
        ? getApiAuditList({
            chg_type_code: checkTypeCode,
            appr_stat_code: checkStatueTypeCode,
            order_list: order,
          })
        : getPkgAuditList({
            chg_type_code: apicheckTypeCode,
            appr_stat_code: apiCheckStatueTypeCode,
            order_list: order,
          });
    }
  };

  // 同意审核
  const agreeItem = (data, portKey) => {
    MessageModal({
      type: "start",
      description: "确定同意发布吗？",
      title: "同意发布",
      okText: "启用",
      onOk: () => {
        if (portKey === "api") {
          projectApi
            .auditApi({
              wf_appr_type_code: "APPROVE",
              is_discarded: data?.chg_type_code === "DISCARD" ? true : false,
              wf_ids: [data?.wf_id],
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                setTableLoading(true);
                getApiAuditList({
                  appr_stat_code: statueTypeCode,
                  order_list: ["updated_time", "desc"].toString(),
                });
              } else {
                message.error(res.message);
              }
            });
        }
        if (portKey === "pkg") {
          projectApi
            .auditPkg({
              wf_appr_type_code: "APPROVE",
              wf_ids: [data?.wf_id],
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                setApiTableLoading(true);
                getPkgAuditList({
                  appr_stat_code: apiStatueTypeCode,
                  order_list: ["updated_time", "desc"].toString(),
                });
              } else {
                message.error(res.message);
              }
            });
        }
      },
    });
  };

  // 撤回审核
  const recallItem = (item, portKey) => {
    MessageModal({
      type: "warning",
      description: "确定要撤回发布吗？",
      title: "撤回发布",
      okText: "撤回",
      onOk: () => {
        if (portKey === "api") {
          projectApi
            .canceledApi({
              wf_id: item?.wf_id,
              api_id: item?.api_id,
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                setTableLoading(true);
                getApiAuditList({
                  appr_stat_code: statueTypeCode,
                  order_list: ["updated_time", "desc"].toString(),
                });
              } else {
                message.error(res.message);
              }
            });
        }
        if (portKey === "pkg") {
          projectApi
            .canceledPkg({
              wf_id: item?.wf_id,
              api_pkg_id: item?.api_pkg_id,
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                setApiTableLoading(true);
                getPkgAuditList({
                  appr_stat_code: apiStatueTypeCode,
                  order_list: ["updated_time", "desc"].toString(),
                });
              } else {
                message.error(res.message);
              }
            });
        }
      },
    });
  };

  // 驳回拒绝审核
  const rejectItem = (data, portKey) => {
    MessageModal({
      type: "warning",
      title: "驳回发布",
      rejectTitle: "驳回意见",
      onOk: (item) => {
        if (portKey === "api") {
          projectApi
            .auditApi({
              wf_appr_type_code: "REJECT",
              wf_ids: [data?.wf_id],
              audit_opinion: item?.apprd_opn,
              is_discarded: data?.chg_type_code === "DISCARD" ? true : false,
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                setTableLoading(true);
                getApiAuditList({
                  appr_stat_code: statueTypeCode,
                  order_list: ["updated_time", "desc"].toString(),
                });
              } else {
                message.error(res.message);
              }
            });
        }
        if (portKey === "pkg") {
          projectApi
            .auditPkg({
              wf_appr_type_code: "REJECT",
              wf_ids: [data?.wf_id],
              audit_opinion: item?.apprd_opn,
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                setApiTableLoading(true);
                getPkgAuditList({
                  appr_stat_code: apiStatueTypeCode,
                  order_list: ["updated_time", "desc"].toString(),
                });
              } else {
                message.error(res.message);
              }
            });
        }
      },
    });
  };

  return (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={5000}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <div className="DataServiceCheckContent">
        <Tabs className="myTabs" onChange={tabChange} defaultActiveKey="1">
          <TabPane tab="我的申请" key="1">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">API服务</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      value={inputValue}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                        if (e.target.value === "") {
                          setTableLoading(true);
                          getApiAuditList({
                            appr_stat_code: statueTypeCode,
                            order_list: ["updated_time", "desc"].toString(),
                          });
                        }
                      }}
                      onPressEnter={(e) => {
                        setTableLoading(true);
                        getApiAuditList({
                          appr_stat_code: statueTypeCode,
                          order_list: ["updated_time", "desc"].toString(),
                          api_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="label">流程状态</div>
                  <div className="value">
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => {
                        setStatueTypeCode(e);
                        setTableLoading(true);
                        getApiAuditList({
                          appr_stat_code: e,
                          order_list: ["updated_time", "desc"].toString(),
                        });
                      }}
                      value={statueTypeCode}
                    >
                      {MyapplyState()?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {dicCodeInfoList?.DG_WF_APPR_STAT?.map(
                              (value) => value[item]
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ServiceCheckTable
                  tableName="myapply"
                  tableLoading={tableLoading}
                  recallItem={recallItem}
                  defaultData={myapplyList}
                  changeOrders={changeOrders}
                  changePagination={changePagination}
                  setTableLoading={setTableLoading}
                  getApiAuditList={getApiAuditList}
                  portKey="api"
                ></ServiceCheckTable>
              </div>
            </div>
            <div className="noReleseInfo" style={{ marginTop: "20px" }}>
              <div className="info">
                <div className="selectInfo">
                  <div className="label">API服务包</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      value={apiInputValue}
                      onChange={(e) => {
                        setApiInputValue(e.target.value);
                        if (e.target.value === "") {
                          setApiTableLoading(true);
                          getPkgAuditList({
                            appr_stat_code: apiStatueTypeCode,
                            order_list: ["updated_time", "desc"].toString(),
                          });
                        }
                      }}
                      onPressEnter={(e) => {
                        setApiTableLoading(true);
                        getPkgAuditList({
                          appr_stat_code: apiStatueTypeCode,
                          order_list: ["updated_time", "desc"].toString(),
                          pkg_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="label">流程状态</div>
                  <div className="value">
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => {
                        setApiTableLoading(true);
                        setApiStatueTypeCode(e);
                        getPkgAuditList({
                          appr_stat_code: e,
                          order_list: ["updated_time", "desc"].toString(),
                        });
                      }}
                      value={apiStatueTypeCode}
                    >
                      {MyapplyState()?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {dicCodeInfoList?.DG_WF_APPR_STAT?.map(
                              (value) => value[item]
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ServiceCheckTable
                  tableName="myapply"
                  // activeKey={getActiveKey()}
                  tableLoading={apiTableLoading}
                  recallItem={recallItem}
                  defaultData={apiMyapplyList}
                  changePagination={changePagination}
                  changeOrders={changeOrders}
                  setApiTableLoading={setApiTableLoading}
                  getPkgAuditList={getPkgAuditList}
                  portKey="pkg"
                ></ServiceCheckTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="待审核" key="2">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">API服务</div>
                  <div className="value">
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      value={inputValue}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                        if (e.target.value === "") {
                          setTableLoading(true);
                          getApiAuditList({
                            appr_stat_code: "IN_APPROVAL",
                            order_list: ["apply_time", "desc"].toString(),
                          });
                        }
                      }}
                      onPressEnter={(e) => {
                        setTableLoading(true);
                        getApiAuditList({
                          appr_stat_code: "IN_APPROVAL",
                          order_list: ["apply_time", "desc"].toString(),
                          api_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ServiceCheckTable
                  tableName="ToCheck"
                  // activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  agreeItem={agreeItem}
                  rejectItem={rejectItem}
                  defaultData={toCheckList}
                  changeOrders={changeOrders}
                  changePagination={changePagination}
                  portKey="api"
                ></ServiceCheckTable>
              </div>
            </div>
            <div className="noReleseInfo" style={{ marginTop: "20px" }}>
              <div className="info">
                <div className="selectInfo">
                  <div className="label">API服务包</div>
                  <div className="value">
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      value={apiInputValue}
                      onChange={(e) => {
                        setApiInputValue(e.target.value);
                        if (e.target.value === "") {
                          setApiTableLoading(true);
                          getPkgAuditList({
                            appr_stat_code: "IN_APPROVAL",
                            order_list: ["apply_time", "desc"].toString(),
                          });
                        }
                      }}
                      onPressEnter={(e) => {
                        setApiTableLoading(true);
                        getPkgAuditList({
                          appr_stat_code: "IN_APPROVAL",
                          order_list: ["apply_time", "desc"].toString(),
                          pkg_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ServiceCheckTable
                  tableName="ToCheck"
                  // activeKey={getActiveKey()}
                  tableLoading={apiTableLoading}
                  agreeItem={agreeItem}
                  rejectItem={rejectItem}
                  defaultData={apiToCheckList}
                  changePagination={changePagination}
                  changeOrders={changeOrders}
                  portKey="pkg"
                ></ServiceCheckTable>
              </div>
            </div>
          </TabPane>

          <TabPane tab="已审核" key="3">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">API服务</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      value={inputValue}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                        if (e.target.value === "") {
                          setTableLoading(true);
                          getApiAuditList({
                            chg_type_code: checkTypeCode,
                            appr_stat_code: checkStatueTypeCode,
                            order_list: ["updated_time", "desc"].toString(),
                          });
                        }
                      }}
                      onPressEnter={(e) => {
                        setTableLoading(true);
                        getApiAuditList({
                          api_name: e.target.value,
                          chg_type_code: checkTypeCode,
                          appr_stat_code: checkStatueTypeCode,
                          order_list: ["updated_time", "desc"].toString(),
                        });
                      }}
                    />
                  </div>
                  <div className="label">变更类型</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => {
                        setCkeckTypeCode(e);
                        setTableLoading(true);
                        getApiAuditList({
                          chg_type_code: e,
                          appr_stat_code: checkStatueTypeCode,
                          order_list: ["updated_time", "desc"].toString(),
                        });
                      }}
                      value={checkTypeCode}
                    >
                      {changeType?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {dicCodeInfoList?.DG_CHG_TYPE?.map(
                              (value) => value[item]
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="label">流程状态</div>
                  <div className="value">
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => {
                        setCheckStatueTypeCode(e);
                        setTableLoading(true);
                        getApiAuditList({
                          chg_type_code: checkTypeCode,
                          appr_stat_code: e,
                          order_list: ["updated_time", "desc"].toString(),
                        });
                      }}
                      value={checkStatueTypeCode}
                    >
                      {checkApprState()?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {dicCodeInfoList?.DG_WF_APPR_STAT?.map(
                              (value) => value[item]
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ServiceCheckTable
                  tableName="check"
                  // activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  defaultData={checkList}
                  changeOrders={changeOrders}
                  changePagination={changePagination}
                  portKey="api"
                ></ServiceCheckTable>
              </div>
            </div>
            <div className="noReleseInfo" style={{ marginTop: "20px" }}>
              <div className="info">
                <div className="selectInfo">
                  <div className="label">API服务包</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      value={apiInputValue}
                      onChange={(e) => {
                        setApiInputValue(e.target.value);
                        if (e.target.value === "") {
                          setApiTableLoading(true);
                          getPkgAuditList({
                            chg_type_code: apicheckTypeCode,
                            appr_stat_code: apiCheckStatueTypeCode,
                            order_list: ["updated_time", "desc"].toString(),
                          });
                        }
                      }}
                      onPressEnter={(e) => {
                        setApiTableLoading(true);
                        getPkgAuditList({
                          pkg_name: e.target.value,
                          chg_type_code: apicheckTypeCode,
                          appr_stat_code: apiCheckStatueTypeCode,
                          order_list: ["updated_time", "desc"].toString(),
                        });
                      }}
                    />
                  </div>
                  <div className="label">变更类型</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => {
                        setApiCkeckTypeCode(e);
                        setApiTableLoading(true);
                        getPkgAuditList({
                          chg_type_code: e,
                          appr_stat_code: apiCheckStatueTypeCode,
                          order_list: ["updated_time", "desc"].toString(),
                        });
                      }}
                      value={apicheckTypeCode}
                    >
                      {changeType?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {dicCodeInfoList?.DG_CHG_TYPE?.map(
                              (value) => value[item]
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="label">流程状态</div>
                  <div className="value">
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => {
                        setApiCheckStatueTypeCode(e);
                        setApiTableLoading(true);
                        getPkgAuditList({
                          chg_type_code: apicheckTypeCode,
                          appr_stat_code: e,
                          order_list: ["updated_time", "desc"].toString(),
                        });
                      }}
                      value={apiCheckStatueTypeCode}
                    >
                      {checkApprState()?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {dicCodeInfoList?.DG_WF_APPR_STAT?.map(
                              (value) => value[item]
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ServiceCheckTable
                  tableName="check"
                  // activeKey={getActiveKey()}
                  tableLoading={apiTableLoading}
                  defaultData={apiCheckList}
                  changePagination={changePagination}
                  changeOrders={changeOrders}
                  portKey="pkg"
                ></ServiceCheckTable>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Scrollbars>
  );
};
export default DataServiceCheck;
